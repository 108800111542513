import axios from "axios";

class CPDService {
    async getCPDs (refresh) {
        let responseObject = null;
        await axios.get("moodle/courses")
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getEnrolledCPDs (id,refresh) {
        let responseObject = null;
        await axios.get(`moodle/courses/${id}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }
}

export default new CPDService();