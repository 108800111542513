import axios from "axios";

class PaymentService {
    async makePayment(receivableId,tariffId,phoneNumber,narration,refresh){
        let responseObject = {};
        await axios.post("payment",null,
            {
                params:
                {
                    receivableId:receivableId,
                    tariffId:tariffId,
                    phoneNumber:phoneNumber,
                    narration:narration
                },
                headers:{
                    Authorization:`bearer ${sessionStorage.getItem("access_token")}`
                }
            }
        )
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.makePayment(receivableId,tariffId,phoneNumber,narration,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            }
        })
        return responseObject;
    }

    async getPaymentTariff(receivableId,criteriaId,refresh){
        let responseObject = {};
        await axios.get('payment/tariff',
            {
                params: {
                    receivableId:receivableId,
                    criteriaId:criteriaId
                },
                headers:{
                    Authorization:`bearer ${sessionStorage.getItem("access_token")}`
                }
            }
        )
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getPaymentTariff(receivableId,criteriaId,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error.message);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }
}

export default new PaymentService();