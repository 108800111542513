import React from 'react'
import {CIHandShake} from "./CoazIcons"
import { FaRegCircleDot } from "react-icons/fa6";

const Partners = () => {
  return (
    <div className='w-full h-auto mt-16 bg-[rgb(238,238,238)]'>
        <div className='flex flex-row w-full h-[80vh]'>
            <div className='flex flex-col space-y-4 justify-center w-1/2 px-16'>
                <CIHandShake size={80} fill='black'/>
                <p className='text-3xl font-leBeauneNew font-semibold'>Partners</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    COAZ collaborates with government agencies, healthcare institutions, and other stakeholders
                    to strengthen the role of clinical officers in Zambia's healthcare system. Learn more about
                    our partnerships and how we work together to advocate for quality healthcare delivery and 
                    professional advancement
                </p>
            </div>
            <div className='flex flex-col w-1/2 shrink-0 bg-[url(/public/images/img_32.jpg)] bg-cover'></div>
        </div>
        <div className='flex flex-col space-y-8 w-[90%] h-auto my-16 mx-auto p-8 bg-[rgb(247,247,247)] border-t-2 border-[rgb(0,175,240)]'>
            <div className='flex flex-row space-x-8 w-full h-32'>
                <div className='w-1/2 h-full'>
                    <Partner img='/images/hpcz.png' description='Collaborates in enforcement of regulations, standards of practice and other functions'/>
                </div>
                <div className='w-1/2 h-full'>
                    <Partner img='/images/hea.png' description='Collaborates with the higher education Authority in matters of training and other matters related'/>
                </div>
            </div>
            <div className='flex flex-row space-x-8 w-full h-32'>
                <div className='w-1/2 h-full'>
                    <Partner img='/images/moh.png' description='Providing Health services and enforcing standards in health service delivery'/>
                </div>
                <div className='w-1/2 h-full'>
                    <Partner img='/images/local_authority.png' description='Works to strengthen local involvement in Health services and others'/>
                </div>
            </div>
        </div>
    </div>
  )
}

const Partner = ({img,description}) => {
    return (
        <div className='flex flex-row space-x-4 w-full h-full'>
            <img src={img} alt='img' className='w-1/4 h-full object-contain'/>
            <p className='w-3/4 h-auto my-auto p-4 text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>{description}</p>
        </div>
        
    )
}

export default Partners