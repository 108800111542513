import React, {useState,useEffect,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import SubMenu from './SubMenu';
import { LiaAngleRightSolid } from "react-icons/lia";
import { useNavigate,NavLink } from 'react-router-dom';

const DropDownItem = ({id,link,name,menus,show,setShow}) => {
  const {convertToPath} = useContext(GlobalContext);
  const [subMenu, setSubMenu] = useState(null);
  const [showSubMenu,setShowSubMenu] = useState(false);

  const handleMouseEnter = (e) => {
    if(menus) {
      if(!showSubMenu) {
        setShowSubMenu(true);
        setSubMenu({
          id:id+'-submenu',
          component:SubMenu,
          menus:menus
        });
      }
    }
  }

  const handleMouseLeave = (e) => {
    if(menus) {
      let thisMenu = document.getElementById(id);
      if(thisMenu) {
        let rect = thisMenu.getBoundingClientRect();
        if(e.clientX < rect.right) {
          setShowSubMenu(false);
        }
      }
    }
  }

  return (
    <div>
      <NavLink to={link?link:''} className={({isActive}) => { return isActive?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'}}>
        <div id={id}
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e)}
            className='flex flex-row w-full px-6 justify-between items-center font-jostBook hover:text-[rgb(0,175,240)] cursor-pointer'
        >
          <p className='w-full overflow-hidden'>{name}</p>
          {menus && <LiaAngleRightSolid size={16} className='flex w-8 shrink-0'/>}
        </div>
      </NavLink>
      {subMenu && (<subMenu.component show={show} setShow={setShow} showSubMenu={showSubMenu} setShowSubMenu={setShowSubMenu} subMenu={subMenu} setSubMenu={setSubMenu}/>)}
    </div>
  )
}

export default DropDownItem