import React, {useEffect} from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import {CIService} from "./CoazIcons"
import AwardsAndCertificates from './AwardsAndCertificates';
import Partners from './Partners';
import Careers from './Careers';

const Services = () => {
    const navigate = useNavigate();
    const {service} = useParams();

    useEffect(() => { 
      if(!service) {
        navigate('/services/awards_and_certificates');
      }
    },[service]);

    return (
        <div className='relative flex flex-col w-full h-auto'>
          <div className='relative flex flex-col w-full h-[50vh] shrink-0 overflow-x-hidden overflow-y-auto'>
            <img src='/images/bg_8.jpg' className='flex w-full h-full object-cover overflow-hidden'/>
            <div className='absolute flex  left-0 top-0 w-full h-full shrink-0 bg-gradient-to-b from-[rgba(0,0,0,.5)] via-transparent to-[rgba(0,0,0,.5)]'/>
            <div className='absolute flex flex-col space-y-4 items-center justify-center top-[166px] bottom-0 w-full h-auto'>
              <div className='flex w-[1px] h-24 shrink-0 border-l border-white'/>
              <p className='text-white text-4xl font-leBeauneNew tracking-wide'> 
                services
              </p>
              <p className='text-white text-center tracking-wide text-4xl lg:text-7xl font-leBeauneNew'> 
                {service?service.replace(/_/g,' '):''}
              </p>
              <div className='flex w-[2px] h-full mx-auto border-r border-white'/>
            </div>
          </div>
            <section className='relative flex flex-col w-full h-auto shrink-0 bg-white'>
                <div className='flex w-[1px] h-24 mx-auto shrink-0 border-l border-[rgb(204,204,204)]'></div>
                <div className='flex flex-col items-center justify-center w-full h-auto mt-8'>
                    <div className='flex items-center justify-center w-auto h-auto mx-auto'>
                        <CIService size={96} fill='rgb(0,175,240)'/>
                    </div>
                    <div className='flex flex-col space-y-12 w-[60%] h-auto'>
                        <p className='mt-8 text-3xl text-center font-leBeauneNew'>our services</p>
                        <p className='w-full text-lg text-center tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            At COAZ, we are dedicated to empowering clinical officers and promoting excellence in 
                            healthcare delivery across Zambia. Our website serves as a hub for information, 
                            resources, and opportunities tailored to the needs of clinical officers and the 
                            healthcare community
                        </p>
                    </div>
                </div>
                {service && service === 'awards_and_certificates' && (<AwardsAndCertificates/>)}
                {service && service === 'partners' && (<Partners/>)}
                {service && service === 'careers' && (<Careers/>)}
            </section>
        </div>
      )
}

export default Services