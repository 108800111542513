import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import EventService from '../services/EventService';
import UserService from '../services/UserService';
import EditEvent from './EditEvent';
import { LiaMoneyBillWaveSolid ,LiaUserTieSolid,LiaUsersSolid,LiaBuilding,LiaClockSolid } from "react-icons/lia";
import { PiMoneyLight} from "react-icons/pi";
import { MdOutlineEditLocation } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { TbUser} from "react-icons/tb";
import { AiOutlineEdit } from "react-icons/ai";

const Event = ({id,onView}) => {
    const {setReloadUser,setDialog,hasAuthority} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [event,setEvent] = useState(null);
    const [imageSize,setImageSize] = useState({width:0,height:0});
    const eventRef = useRef(null);

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    useEffect(() => {
        ( async () => {
            await UserService.getCurrentUser()
            .then((response) => {
                if(response.content) {
                    setUser(response.content);
                }  else {
                    setUser(null);
                    onView(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setUser(null);
                onView(null);
                setReloadUser({reload:true});
            })
        }
        )();
        ( async () => {
            await EventService.getEvent(id,false)
            .then((response) => {
                if(response.content) {
                    response.content.date = new Date(response.content.date);
                    setEvent(response.content);
                }  else {
                    setEvent(null);
                    onView(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setEvent(null);
                onView(null);
                setReloadUser({reload:true});
            })
        }
        )();

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let width = entry.target.getBoundingClientRect().width;
                let height = (width * 9)/16;
                setImageSize({width:width,height:height});
            }
        });

        if(eventRef.current) {
            observer.observe(eventRef.current);
        }
        return () => {
            observer.disconnect();
        }
    },[])
  return (
    <div className='flex flex-col w-full h-auto overflow-hidden'>
        {event?
            <div className='flex flex-col w-full h-auto max-h-[80vh] py-8 items-center no-scrollbar overflow-x-hidden overflow-y-auto bg-white font-jostBook'>
                <div ref={eventRef} style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
                    className='flex flex-col w-[90%] lg:w-3/4 h-auto bg-white rounded-md'>
                    {/* <img style={{width:imageSize.width+'px',height:imageSize.height+'px'}}
                        src={event.image?event.image:'/images/event.png'} 
                        className=' object-cover'
                    /> */}
                    <div className='flex flex-col w-full h-auto p-8'>
                        <p className='text-sm text-[rgb(180,180,180)] font-jostSemi tracking-wide'>
                            {event.date.toLocaleString('default', { month: 'long' })+' '+event.date.getDate()+', '+event.date.getFullYear()}
                        </p>
                        <p className='flex w-full h-auto pt-4 lg:text-lg text-[rgb(59,59,59)] font-semibold font-leBeauneNew'>
                            {event.name}
                        </p>
                        <p className='flex w-full text-lg text-[rgb(100,100,100)] font-jostMedium capitalize'>
                            {event.description}
                        </p>
                        <div className='flex flex-col space-y-2 w-auto h-auto mt-8'>
                            <div className='flex flex-row space-x-2 items-center'>
                                <LiaClockSolid size={16} className='text-[rgb(0,175,240)]'/>
                                <p className='text-xs text-[rgb(0,175,240)] font-jostSemi'>
                                    {event.startHour+':'+event.startMin+' - '+event.endHour+':'+event.endMin}
                                </p>
                            </div>
                            <div className='flex flex-row space-x-2 items-center'>
                                <SlLocationPin size={16} className='text-[rgb(0,175,240)]'/>
                                <p className='text-xs text-[rgb(0,175,240)] font-jostSemi'>{event.venue}</p>
                            </div>
                            {user && hasAuthority(user,'UPDATE_EVENT') && <button 
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDialog({
                                        show:true,
                                        Component:() => 
                                            <EditEvent id={id} setOriginalEvent={(updatedEvent)=> setEvent(updatedEvent)}/>
                                    })
                                }}
                                className='flex w-fit flex-row space-x-2 items-center text-sm shrink-0 text-[rgb(0,175,240)] font-jostSemi hover:underline'>
                                <AiOutlineEdit size={24}/>
                                <p>Edit</p>
                            </button>}
                        </div>
                    </div>
                    <div className='flex flex-col space-y-8 w-full h-auto px-8 py-16 shrink-0 bg-[rgb(243,244,245)]'>
                        <div className='flex flex-col w-full h-auto space-y-4'>
                            <p className='text-3xl text-[rgb(59,59,59)] font-semibold'>Participants</p>
                            {event.users && event.users.map((user,i) => 
                                <div key={i} className='flex flex-row w-full h-auto space-x-4'>
                                    <TbUser size={20} className='flex text-[rgb(85,85,85)] m-auto shrink-0'/>
                                    <div className='flex flex-row w-full h-auto'>
                                        <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {user.firstname}
                                        </p>
                                        <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {user.lastname}
                                        </p>
                                        <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {user.role?user.role.displayName:''}
                                        </p>
                                    </div>
                                </div>)
                            }
                        </div>
                        <div className='flex flex-col w-full h-auto space-y-4'>
                            <p className='text-3xl text-[rgb(59,59,59)] font-semibold'>Tariffs</p>
                            {event.tariffs && event.tariffs.map((tariff,i) => 
                                <div key={i} className='flex flex-row w-full h-auto space-x-4'>
                                    <LiaMoneyBillWaveSolid  size={20} className='flex text-[rgb(85,85,85)] m-auto shrink-0'/>
                                    <div className='flex flex-row w-full h-auto'>
                                        <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {tariff.name}
                                        </p>
                                        <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {tariff.description}
                                        </p>
                                        <p className='w-1/3 text-sm text-right tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                            {USDecimal.format(tariff.price)}
                                        </p>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div> :
            <div>
                
            </div>
        }
    </div>
  )
}

export default Event