import Menu from './Menu';
import Footer from './Footer';
import React, {useEffect, useState, useContext,useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import axios from "axios";
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import Content from './Content';
import UserService from '../services/UserService';
import { IoCloseOutline } from "react-icons/io5";
import { LiaAngleRightSolid } from "react-icons/lia";
import { LiaAngleLeftSolid } from "react-icons/lia";
import {menus,getSettings} from '../data';

const Main = () => {
    const {reloadUser,setReloadUser,mainElementRef,mobileModeMenuIndex,setMobileModeMenuIndex,showMobileModeMenu,
        setShowMobileModeMenu,hasAuthority} = useContext(GlobalContext);
    const [user, setUser] = useState(null);
    const [dropDown,setDropDrown] = useState(() => <div>dropdown</div>);
    const [scroll, setScroll] = useState({onScroll:() => {}});
    const dropDownMenuRef = useRef(null);
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const handleScroll = (e) => {
        scroll.onScroll(e,showMobileModeMenu);
    }

    const [root,setRoot] = useState({name:'root',menus:menus});

    useEffect(() => {
        if(pathname === '/') {
            navigate('/home');
        }
        if(reloadUser.reload) {
            setReloadUser({reload:false});
            ( async () => {
                await UserService.getCurrentUser(false)
                .then((response) => {
                    if(response.content) {
                        setUser(response.content);
                        // Creates a complete copy of menus
                        let rootMenus = JSON.parse(JSON.stringify(menus));
                        response.content.role && rootMenus.push(getSettings(response.content,hasAuthority));
                        setRoot({name:'root',menus:rootMenus});
                    }  else {
                        setUser(null);
                        UserService.logout();
                        setRoot({name:'root',menus:menus});
                        navigate('/home');
                    }
                })
                .catch((error) => {
                    setUser(null);
                    UserService.logout();
                    setRoot({name:'root',menus:menus});
                    navigate('/home');
                })
            }
            )(); 
        }
    },[reloadUser]);

    return (
        <div className='flex flex-row w-full h-full overflow-hidden'>
            <div ref={mainElementRef}
                onScroll={handleScroll} 
                style={{transition:'all .5s ease-in-out'}}
                className={`fixed flex flex-col ${showMobileModeMenu?'-left-3/4':'left-0'} w-full h-full overflow-x-hidden overflow-y-auto z-20`}>
                <Menu mainElementRef={mainElementRef} scroll={scroll} setScroll={setScroll}/>
                <Content/>
                <Footer/>
            </div>
            <div style={{transition:'all .5s ease-in-out'}}
                className={`${showMobileModeMenu?' opacity-100':'opacity-0'} fixed flex flex-col right-0 w-3/4 h-full overflow-hidden bg-[rgb(0,175,240)] px-12`}>
                {root && <DropDown root={root}/>}
            </div>
        </div> 
    )
}

export default Main

const DropDown = ({root}) => {
    const {mobileModeMenuIndex,setMobileModeMenuIndex} = useContext(GlobalContext);
    const [pages,setPages] = useState([]);
    const dropDownRef = useRef(null);
    
    const addPages = (menu,newPages,index) => {
        if(menu.menus) {
            newPages.push(<DropDownPage key={index.value} dropDown={menu} index={index.value}/>);
            menu.menusPage = index.value;
            index.value += 1;
            for(let subMenu of menu.menus) {
                addPages(subMenu,newPages,index);
            }
        }
    }

    useEffect(() => {
        let newPages = [];
        let index = {value:0};
        addPages(root,newPages,index);
        setPages(newPages);
        setMobileModeMenuIndex(0);
    },[root])

    return (
        <div ref={dropDownRef}
            className='flex flex-row w-full h-full'>
            {pages}
        </div>
    )
}

const DropDownPage = ({dropDown,index}) => {
    const {mobileModeMenuIndex,setMobileModeMenuIndex,showMobileModeMenu,setShowMobileModeMenu,
        mobileModeMenuIndexs,setMobileModeMenusIndex} = useContext(GlobalContext);
    const ref = useRef(null);
    useEffect(() => {
    },[])
    return (
        <div style={{transition:'all .5s ease-in-out'}}
            className={`flex flex-col ${index == mobileModeMenuIndex?'w-full opacity-100':'w-0 opacity-0'} overflow-hidden`}>
            <div className='flex w-full h-16 items-center'>
                <button 
                    onClick={(e) => 
                        {
                            if(mobileModeMenuIndex < 1) {
                                setShowMobileModeMenu(!showMobileModeMenu);
                            } else {
                                mobileModeMenuIndexs.pop();
                                setMobileModeMenuIndex(mobileModeMenuIndexs.slice(-1));
                            }
                        }
                    } 
                    className='flex w-8 h-8 items-center shrink-0 text-[rgba(255,255,255,.8)]'>
                    {index < 1?<IoCloseOutline size={32}/>:<LiaAngleLeftSolid size={32}/>}
                </button>
            </div>
            <div className='flex w-full h-full'>
                <div className={`flex flex-col w-full h-fit overflow-hidden border-b border-[rgba(255,255,255,.2)]`}>
                    {dropDown && dropDown.menus && dropDown.menus.map((menu,i) => 
                        menu.menus?
                            <div key={i}
                                onClick={(e) => {
                                    if(menu.menus) {
                                        mobileModeMenuIndexs.push(menu.menusPage);
                                        setMobileModeMenuIndex(menu.menusPage);
                                    }
                                }} 
                                className='flex flex-row justify-between w-full h-16 items-center text-[rgba(255,255,255,.8)] border-t border-[rgba(255,255,255,.2)]'>
                                <p className='flex font-[arial] font-semibold text-xs uppercase '>{menu.name}</p>
                                <LiaAngleRightSolid size={16} className='flex shrink-0'/>
                            </div>
                            :
                            <NavLink key={i} to={menu.link?menu.link:'/'}>
                                <div key={i} 
                                    onClick={(e) => setShowMobileModeMenu(false)} 
                                    className='flex flex-row justify-between w-full h-16 items-center text-[rgba(255,255,255,.8)] border-t border-[rgba(255,255,255,.2)]'>
                                    <p style={{transition:'all .5s ease-in-out'}}
                                        className='flex font-[arial] font-semibold text-xs uppercase'>{menu.name}</p>
                                </div>
                            </NavLink>
                        
                    )}
                </div>
            </div>
        </div>
    )
}