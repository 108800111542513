import React, {useState, useEffect,useRef,useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import { BiSolidCloudUpload } from "react-icons/bi";
import Input from './Input'
import Select from './Select'
import Checkbox from './Checkbox';
import Scrollable from './Scrollable';
import Inputs from './Inputs';
import FormValidator from './FormValidator';
import LoadingIcons from 'react-loading-icons';
import Message from './Message';

const UserForm = ({pages,onSubmit,errors,message}) => {
    const {mobileMode,loading} = useContext(GlobalContext);
    const [index,setIndex] = useState({value:-1});
    const [showPrevious,setShowPrevious] = useState(false);
    const [showNext,setShowNext] = useState(false); 
    const [touchStart,setTouchStart] = useState(null);
    const [touchEnd,setTouchEnd] = useState(null);
    const mainContainerRef = useRef(null);

    const slide = (direction) => {
        let formWindow = document.getElementById('formwindow');
        let inputForm = document.getElementById('inputform');
        if(formWindow && inputForm) {
            let rect = formWindow.getBoundingClientRect();
            let categories = inputForm.getElementsByClassName('page');
            index.value += direction;
            if(index.value >= categories.length) {
                index.value = 0;
            } else if(index.value < 0) {
                index.value = categories.length - 1;
            }
            let left = rect.width*-1;
            let i = index.value - 1;
            let counter = 0;
            while (counter < categories.length) {
                if(i >= categories.length) {
                    i = 0;
                } else if(i < 0) {
                    i = categories.length - 1;
                }
                
                let category = categories[i];
                category.style.left = left+'px';
                if(direction == 1) {
                    if(i === index.value || i === index.value - 1 || (i === categories.length - 1 && index.value === 0)){
                        category.style.transition = 'left .5s ease-in-out';
                    } else {
                        category.style.transition = '';
                    }
                } else {
                    if(i === index.value || i === index.value + 1 || (i === 0 && index.value === categories.length - 1)){
                        category.style.transition = 'left .5s ease-in-out';
                    } else {
                        category.style.transition = '';
                    }
                }
                left += rect.width;
                i++;
                counter++;
            };
            let newIndex = {value:index.value};
            setIndex(newIndex);
        }
    }

    const handleNext = (e) => {
        if(e) e.preventDefault();
        slide(1);
    }

    const handlePrevious = (e) => {
        if(e) e.preventDefault();
        slide(-1);
    }

    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = (e) => {
        if(!touchStart || !touchEnd) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            handleNext(e)
        }
        if (isRightSwipe) {
            handlePrevious(e)
        }
    }

    useEffect(() => {
        index.value = -1;
        handleNext();
    },[]);

  return (
    <FormValidator>
        <div 
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className='relative flex flex-col w-full h-full overflow-hidden'>
            <div className='flex flex-row space-x-2 items-center justify-center h-12'>
                {pages.map((page,i) => 
                    <div key={i} style={{transition:'all .5s ease-in-out'}} className={`flex w-8 h-[2px] rounded-full ${index.value === i?'bg-[rgb(0,175,240)]':'bg-[rgba(84,84,87,.3)]'}`}></div>
                )}
            </div>
            <div id='formwindow'
                className='relative flex flex-col w-full h-full m-auto overflow-hidden'>
                <div id='inputform' className='flex flex-row w-auto h-full'>
                    {pages}
                </div>
            </div>
            <Message message={message}/>
            <div className='flex shrink-0 w-full p-4'>
                <button id='form-submit' onClick={onSubmit} className='flex shrink-0 h-10 m-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                    Submit
                </button>
            </div>
        </div>
        {!mobileMode &&
        <>
            <div onMouseEnter={(e) => setShowPrevious(true)}
                onMouseLeave={(e) => setShowPrevious(false)}
                className='absolute flex -left-4 w-10 top-0 bottom-0'>
                <button onClick={(e) => handlePrevious(e)}
                        style={{backdropFilter:'blur(10px)',
                                boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                transition: 'all 0.3s linear'
                            }}
                        className={`${showPrevious?'visible opacity-100':'invisible opacity-0'} flex w-10 h-10 m-auto bg-[rgb(255,255,255)] rounded-full`}
                >
                    <LiaAngleLeftSolid size={16} className='flex m-auto text-gray-600'/>
                </button>
            </div>
            <div onMouseEnter={(e) => setShowNext(true)}
                onMouseLeave={(e) => setShowNext(false)}
                className='absolute flex -right-4 w-10 top-0 bottom-0'>
                <button onClick={(e) => handleNext(e)}
                        style={{backdropFilter:'blur(10px)',
                                boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                transition: 'all 0.3s linear'
                            }}
                        className={`${showNext?'visible opacity-100':'invisible opacity-0'} flex w-10 h-10 m-auto bg-white rounded-full`}
                >
                    <LiaAngleRightSolid size={16} className='flex m-auto text-gray-600'/>
                </button>
            </div>
        </>
        }
    </FormValidator>
  )
}

export function Page({Icon,id,name,inputs}) {
    const [pageWidth,setPageWidth] = useState(0);

    useEffect(() => {
        const formWindow = document.getElementById('formwindow');

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const width = entry.target.getBoundingClientRect().width;
                const page = document.getElementById(id);
                if(page) {
                    //setPageWidth(width);
                    page.style.width = width+'px';
                    const submit = document.getElementById('form-submit');
                    if(submit) {
                        submit.style.width = 100+'px';
                    }
                }
            }
        });

        if(formWindow) {
            observer.observe(formWindow)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    return (
        <div id={id} 
            /* style={{width:pageWidth+'px'}} */
            className='page absolute flex flex-col h-full shrink-0'>
            <Scrollable>
                <div className='flex flex-col w-full'>
                    <Icon size={40} className='flex mx-auto shrink-0 text-[rgba(84,84,87,.7)]'/>
                    <p className='flex w-auto items-center mx-auto mb-4  text-[rgb(84,84,87)]'>{name}</p>
                    <Inputs inputs={inputs} minWidth={240} paddingX={32} spaceX={32} id={id+'_inputs'}/>
                </div>
            </Scrollable>
        </div> 
    )
}

export function ImageUpload({Icon,id,image,setImage}) {
    useEffect(() => {
        let formWindow = document.getElementById('formwindow');
        let category = document.getElementById(id);
        if(formWindow && category) {
            let rect = formWindow.getBoundingClientRect();
            category.style.width = rect.width+'px';
        }
    },[]);

    return (
        <div id={id} className='page absolute flex flex-col h-full p-2 shrink-0'>
            <Icon size={40} className='flex mx-auto shrink-0 text-[rgba(84,84,87,.7)]'/>
            <p className='flex w-auto items-center mx-auto mb-4  text-[rgb(84,84,87)]'>Image</p>
            <div 
                id="drop_zone" 
                onDrop={(e) => {
                    e.preventDefault();
                    setImage(e.dataTransfer.files[0]);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                className={`flex flex-col w-full h-full shrink-0 items-center justify-center ${image?'':'border border-dashed'} bg-[rgb(247,247,247)] rounded-lg overflow-hidden`}>
            {(image?
                <img
                    alt="not found"
                    className='flex w-full h-full object-contain'
                    src={URL.createObjectURL(image)}
                />
            :
                <div className='flex flex-col items-center justify-center'>
                    <BiSolidCloudUpload size={64} className='text-gray-400'/>
                    <p className='text-xl text-gray-400'>Drag and drop files here</p>
                    <p className='text-gray-400'>-or-</p>
                    <input
                        type='file'
                        id='img'
                        name='userImage'
                        className='hidden'
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                        }}
                    />
                    <label 
                        htmlFor='img' 
                        className='flex shrink-0 w-auto h-10 items-center justify-center text-xl text-gray-400 hover:underline'
                    >
                        Browse
                    </label>
                </div>
            )}
            </div>
            <button onClick={() => {setImage(null)}} className={`${image?'flex':'hidden'} w-16 h-8 m-auto  text-red-600`}>Remove</button>
        </div> 
    )
}

export default UserForm