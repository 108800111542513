import React, {useState,useEffect} from 'react'

const CountDown = ({title,date}) => {
    const [time,setTime] = useState(date.getTime() - new Date().getTime());

    const getFormatedTime = (time) => {
        let totalSeconds = parseInt(Math.floor(time / 1000));
        let totalMinutes = parseInt(Math.floor(totalSeconds / 60));
        let totalHours = parseInt(Math.floor(totalMinutes / 60));
        let days = parseInt(totalHours / 24);

        let seconds = parseInt(totalSeconds % 60);
        let minutes = parseInt(totalMinutes % 60);
        let hours = parseInt(totalHours % 24);

        return <div className='flex flex-col w-auto max-w-full h-auto space-y-4 items-center font-leBeauneNew bg-[rgba(0,0,0,.2)] p-4 rounded-lg'>
                <p className='flex items-center justify-center font-semibold text-center text-5xl text-white uppercase'>{title}</p>
                <div className='flex flex-row w-full h-auto space-x-2 items-center justify-between '>
                    <Counter count={days} label='days'/>
                    <Counter count={hours} label='hours'/>
                    <Counter count={minutes} label='minutes'/>
                    <Counter count={seconds} label='seconds'/>
                </div>
            </div>;
    }

    useEffect(() => {
        setTimeout(() => {
            setTime(date.getTime() - new Date().getTime());
        },1000);
    },[time]);
  return (
    <div className='flex w-full h-auto items-center justify-center'>{getFormatedTime(time)}</div>
  )
}

const Counter = ({count,label}) => {
    return (
        <div className='flex flex-col w-32 h-32 border border-white rounded-md overflow-hidden'>
            <p className='flex h-2/3 items-center justify-center text-6xl text-[rgb(0,175,240)] bg-white'>{count}</p>
            <p className='flex h-1/3 items-center justify-center text-sm md:text-xl lg:text-2xl text-white'>{label}</p>
        </div>
    )
}

export default CountDown