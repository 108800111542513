import React, {useState,useEffect,useRef,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom'
import UserService from '../services/UserService';
import { PiPrinter } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid,LiaAngleLeftSolid,LiaAngleRightSolid } from "react-icons/lia";
import Payment from './Payment';

const Account = ({id}) => {
    const [user,setUser] = useState(null);
    const [entries,setEntries] = useState([]);
    const [selected,setSelected] = useState(null);
    const [deposit,setDeposit] = useState(null);
    const [message,setMessage] = useState(null);

    const mainContainerRef = useRef(null);
    const tableRef = useRef(null);
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const [tableContainerHeight,setTableContainerHeight] = useState(0);
    const [tableHeight,setTableHeight] = useState(0);
    const [tHeaderHeight,setTHeaderHeight] = useState(32);
    const [tBodyHeight,setTBodyHeight] = useState(0);
    const [tFooterHeight,setTFooterHeight] = useState(32);
    const [messageHeight,setMessageHeight] = useState(32);
    const [buttonsHeight,setButtonsHeight] = useState(80);
    const [debits,setDebits] = useState(0.0);
    const [credits,setCredits] = useState(0.0);
    const [balance,setBalance] = useState(0.0);
    const [showArrows,setShowArrows] = useState(false);

    const calcHeights = (mainContainerHeight) => {
        let height = mainContainerHeight - (buttonsHeight + messageHeight);
        setTableContainerHeight(height);
        setTableHeight(height);
        height -= (tHeaderHeight + tFooterHeight);
        setTBodyHeight(height);
    }

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const columns = [
        {
            name:"date",
            label:"Date"
        },
        {
            name:"description",
            label:"Description"
        },
        {
            name:"ref",
            label:"Ref."
        },
        {
            name:"debit",
            label:"Debit (ZMW)"
        },
        {
            name:"credit",
            label:"Credit (ZMW)"
        },
        {
            name:"balance",
            label:"Balance (ZMW)"
        }
    ]

    const footerColumns = [
        {
            name:"date",
            label:""
        },
        {
            name:"description",
            label:"Totals"
        },
        {
            name:"ref",
            label:""
        },
        {
            name:"debit",
            label:debits
        },
        {
            name:"credit",
            label:credits
        },
        {
            name:"balance",
            label:balance
        }
    ]

    const createEntries = (ledgerEntries) => {
        let entryObjects = [];
        let newDebits = 0.0;
        let newCredits = 0.0;
        let newBalance = 0.0;
        for(let entry of ledgerEntries) {
            if(entry.transaction) {
                if(entry.entryType === 'DEBIT') {
                    newBalance -= entry.transaction.amount;
                    newDebits += entry.transaction.amount;
                } else {
                    newBalance += entry.transaction.amount;
                    newCredits += entry.transaction.amount;
                }
                entryObjects.push({
                    date: new Date(entry.transaction.createdOn),
                    description: entry.transaction.message,
                    ref: entry.transaction.transactionId,
                    dabit: entry.entryType === 'DEBIT'?entry.transaction.amount:'',
                    credit: entry.entryType === 'CREDIT'?entry.transaction.amount:'',
                    balance: newBalance
                })
            }
        }
        setDebits(newDebits);
        setCredits(newCredits);
        setBalance(newBalance);
        setEntries(entryObjects);
    }

    useEffect(() => {
        ( async () => {
            if(id) {
                await UserService.getUser(id,false)
                .then((response) => {
                    if(response.content) {
                        setUser(response.content);
                        if(response.content.ledgerAccount) {
                            console.log(response.content.ledgerAccount.ledgerEntries);
                            createEntries(response.content.ledgerAccount.ledgerEntries);
                        }  
                    }  else {
                        setUser(null);
                    }
                })
                .catch((error) => {
                    setUser(null);
                })
            } else {
                await UserService.getCurrentUser(false)
                .then((response) => {
                    if(response.content) {
                        setUser(response.content);
                        if(response.content.ledgerAccount) {
                            createEntries(response.content.ledgerAccount.ledgerEntries);
                        }  
                    }  else {
                        setUser(null);
                    }
                })
                .catch((error) => {
                    setUser(null);
                })
            }
            
        }
        )();
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let height = entry.target.getBoundingClientRect().height;
                calcHeights(height);
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    let timerId;

  return (
        <div ref={mainContainerRef} className='flex flex-col w-full h-full text-sm'>
            <div style={{height:tableContainerHeight + 'px'}}  className='relative flex flex-row w-full'>
                {showArrows &&
                    <div style={{top:'0px',bottom:'0px'}} 
                        className='absolute flex -left-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: -32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.3s linear'
                                }}
                            className={`${true?'visible opacity-100':'invisible opacity-0'} flex w-10 h-10 m-auto bg-[rgb(255,255,255)] rounded-full`}
                        >
                            <LiaAngleLeftSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
                <div className='flex flex-col w-full h-full px-5 overflow-hidden'>
                    <div ref={tableRef} 
                        className='flex flex-col w-full text-sm  no-scrollbar overflow-x-auto overflow-y-hidden'>
                        <div ref={headerRef}
                            style={{height: tHeaderHeight + 'px',transition:'all .5s ease-in-out'}}
                            className='flex flex-row w-fit shrink-0 font-jostBold text-black border-b cursor-pointer'
                            >
                            {columns.map((column,i) => 
                                <div key={i} className={column.name+' flex h-full items-center  overflow-hidden'}>
                                    <p style={{textAlign:column.name === 'debit' || column.name === 'credit' || column.name === 'balance'?'right':'left'}}
                                        className='w-full shrink-0 px-2 whitespace-nowrap'>
                                        {column.label}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div style={{height: tBodyHeight + 'px'}}  
                            className='flex w-fit no-scrollbar overflow-x-auto overflow-y-hidden'>
                            {entries && <Page page={entries} columns={columns} tableRef={tableRef} headerRef={headerRef} footerRef={footerRef} USDecimal={USDecimal}/>}
                        </div>
                        <div ref={footerRef}
                            style={{height: tFooterHeight + 'px'}}
                            className='flex flex-row w-fit shrink-0 font-jostBold border-t text-black cursor-pointer'
                            >
                            {footerColumns.map((column,i) => 
                                <div key={i} 
                                    className={column.name+' flex items-center h-full overflow-hidden'}>
                                    <p style={{textAlign: typeof column.label === 'number'?'right':'left'}}
                                        className=' w-full shrink-0 items-center px-2'>
                                        {typeof column.label === 'number'?USDecimal.format(column.label):column.label}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showArrows &&
                    <div style={{top:'0px', bottom:'0px'}} 
                        className='absolute flex -right-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    //setDisableLScrollBtn(false);
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: 32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.5s linear'
                                }}
                            className={`${false?'invisible opacity-0':'visible opacity-100'} flex w-10 h-10 m-auto bg-white rounded-full`}
                        >
                            <LiaAngleRightSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
            </div>
            <div style={{height:messageHeight + 'px'}} className='flex w-full shrink-0 items-center'>
                <p className={`w-full font-jostBook italic text-center text-sm whitespace-nowrap overflow-hidden overflow-ellipsis ${message && !message.success?'text-red-600':'text-green-600'}`}>{message?message.content:''}</p>
            </div>
            <div style={{height:buttonsHeight + 'px'}} className='flex flex-row space-x-4 w-full shrink-0 items-center px-5 text-sm font-jostBook text-[rgb(93,93,93)] bg-[rgb(247,247,247)]'>
                <button 
                    onClick={(e) => setDeposit(() => <Payment setDeposit={setDeposit}/>)} 
                    className='flex flex-col items-center justify-between w-auto h-16 shrink-0 text-xs whitespace-nowrap capitalize'>
                    <div className='flex w-10 h-10 items-center justify-center shrink-0 bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)] shadow-md rounded-lg'>
                        <LiaMoneyBillWaveSolid size={32}/>
                    </div>
                    Deposit
                </button>
                <button 
                    onClick={(e) => {}} 
                    className='flex flex-col items-center justify-between w-auto h-16 shrink-0 text-xs whitespace-nowrap capitalize'>
                    <div className='flex w-10 h-10 items-center justify-center shrink-0 bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)] shadow-md rounded-lg'>
                        <PiPrinter size={32}/>
                    </div>
                    Print Statement
                </button>
            </div> 
            {deposit &&
                <div className='fixed flex top-0 left-0 w-screen h-screen items-center justify-center'>
                    {deposit}
                </div>
            }       
        </div>  
  )
}

const Page = ({page,columns,tableRef,headerRef,footerRef,USDecimal}) => {
    const {getTextWidth} = useContext(GlobalContext);
    const pagesRef = useRef(null);

    useEffect(() => {
        if(!pagesRef.current) {
            return;
        }
        if(!tableRef.current) {
            return;
        }
        if(!headerRef.current) {
            return;
        }
        let columnWidths = [];
        let totalWidth = 0;
        for(let column of columns) {
            const columnHeader = headerRef.current.getElementsByClassName(column.name)[0];
            if(!columnHeader) {
                continue;
            }
            const columnLabel = columnHeader.getElementsByTagName('p')[0];
            const columnFilter = columnHeader.getElementsByTagName('div')[0];
            let width = 0;
            if(columnLabel){
                width = getTextWidth(columnLabel.textContent,'Jost-700-Bold',14)+16;
            }
            if(columnFilter){
                const filterWidth = columnFilter.getBoundingClientRect().width;
                width = filterWidth > width?filterWidth:width;  
            }
            const elements = pagesRef.current.getElementsByClassName(column.name);
            for(let element of elements){
                let w = getTextWidth(element.textContent,'Jost-400-Book',14)+16;
                if(w > width){
                    width = w;
                }
            }
            
            let columnFooter = null;
            if(footerRef.current) {
                columnFooter = footerRef.current.getElementsByClassName(column.name)[0];
                if(columnFooter) {
                    const columnFooterLabel = columnFooter.getElementsByTagName('p')[0];
                    if(columnFooterLabel){
                        const footerWidth = getTextWidth(columnFooterLabel.textContent,'Jost-700-Bold',14)+16;
                        width = footerWidth > width?footerWidth:width;  
                    }
                }
            }
            
            columnHeader.style.width = width+'px';
            for(let element of elements){
                element.style.width = width+'px';
            }
            if(columnFooter){
                columnFooter.style.width = width+'px';
            } 
            columnWidths.push(width);
            totalWidth += width;
        }
        const tableWidth = tableRef.current.getBoundingClientRect().width;
        if(totalWidth < tableWidth) {
            for(let i = 0; i < columns.length; i++) {
                const column = columns[i];
                const colWidth = columnWidths[i];
                if(colWidth) {
                    let width = colWidth/totalWidth * tableWidth;
                    const columnHeader = headerRef.current.getElementsByClassName(column.name)[0];
                    columnHeader.style.width = width+'px';
                    const elements = pagesRef.current.getElementsByClassName(column.name);
                    for(let element of elements){
                        element.style.width = width+'px';
                    }
                    const columnFooter = footerRef.current.getElementsByClassName(column.name)[0];
                    if(columnFooter) {
                        if(columnFooter){
                            columnFooter.style.width = width+'px';
                        }
                    }
                }
             }
        }
    },[page]);

    return (
        <div ref={pagesRef} className='flex flex-col w-fit h-full shrink-0 no-scrollbar overflow-y-auto'>
            {page.map((entry,i) =>
                <div key={i}
                    className={`flex flex-row w-fit h-10 shrink-0 items-center font-jostBook text-[rgb(93,93,93)] cursor-pointer`}>
                    {columns.map((column,i) => 
                        <div key={i} 
                            style={{textAlign:typeof entry[column.name] === 'number'?'right':'left'}}
                            className={column.name+' w-fit start-0 px-2 whitespace-nowrap'}>
                            {entry[column.name] instanceof Date?entry[column.name].toLocaleDateString():
                            typeof entry[column.name] === 'boolean'?entry[column.name]?'Yes':'No':
                            typeof entry[column.name] === 'number'?USDecimal.format(entry[column.name]):
                            entry[column.name]}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Account