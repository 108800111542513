
import React, {useState,useEffect,useRef,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,NavLink } from 'react-router-dom';
import UserService from '../services/UserService';
import CPDService from '../services/CPDService';
import { PiPrinter } from "react-icons/pi";
import { LiaMoneyBillWaveSolid,LiaAngleLeftSolid,LiaAngleRightSolid } from "react-icons/lia";

const CPDTable = ({id}) => {
    const {setReloadUser, setDialog} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [cpds,setCPDs] = useState([]);
    const [allCPDs,setAllCPDs] = useState([]);
    const [enrolledCPDs,setEnrolledCPDs] = useState([]);
    const [selected,setSelected] = useState(null);
    const [pageNo,setPageNo] = useState(0);
    const [pageSize,setPageSize] = useState(0);
    const [totalElements,setTotalElements] = useState(0);
    const [totalPages,setTotalPages] = useState(0);
    const [last,setLast] = useState(true);
    const [deposit,setDeposit] = useState(null);
    const [message,setMessage] = useState(null);

    const mainContainerRef = useRef(null);
    const tableRef = useRef(null);
    const headerRef = useRef(null);
    const [tableContainerHeight,setTableContainerHeight] = useState(0);
    const [tableHeight,setTableHeight] = useState(0);
    const [tHeaderHeight,setTHeaderHeight] = useState(32);
    const [tBodyHeight,setTBodyHeight] = useState(0);
    const [messageHeight,setMessageHeight] = useState(32);
    const [buttonsHeight,setButtonsHeight] = useState(80);
    const [showArrows,setShowArrows] = useState(false);

    const calcHeights = (mainContainerHeight) => {
        let height = mainContainerHeight - (buttonsHeight + messageHeight);
        setTableContainerHeight(height);
        setTableHeight(height);
        height -= (tHeaderHeight);
        setTBodyHeight(height);
    }

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const onDay = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        if(value > 31) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onMonth = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        if(value > 12) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onNumber = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onBoolean = (e) => {
        const value = e.target.value === 'Yes'?true:e.target.value === 'No'?false:null;
        filter[e.target.name] = value;
        doFilter(allCPDs,enrolledCPDs);
        setFilter({...filter, [e.target.name]: value});
    };
    const onEnrolled = (e) => {
        const value = e.target.value;
        filter[e.target.name] = value;
        doFilter(allCPDs,enrolledCPDs);
        setFilter({...filter, [e.target.name]: value});
    };
    const onChange = (e) => {
        const value = e.target.value;
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    };

    const [filter,setFilter] = useState({
        id:'',
        shortname:'',
        fullname:'',
        startDateDay:'',
        startDateMonth:'',
        startDateYear:'',
        enrolled:null
    });
    
    const [booleanOptions,setBooleanOptions] = useState(['Yes','No']);
    const [focused,setFocused] = useState('');
    const columns = [
        {
            name:'id',
            label:'Id',
            Filter: () => 
                <input 
                    type='text' 
                    name='id'
                    value={filter.id}
                    autoFocus={focused === 'id'}
                    placeholder='Filter...'
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'shortname',
            label:'Shortname',
            Filter: () => 
                <input 
                    type='text' 
                    name='shortname'
                    value={filter.shortname}
                    autoFocus={focused === 'shortname'}
                    placeholder='Filter...'
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'fullname',
            label:'Fullname',
            Filter: () => 
                <input 
                    type='text' 
                    name='fullname'
                    value={filter.fullname}
                    autoFocus={focused === 'fullname'}
                    placeholder='Filter...'
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'startDate',
            label:'Start Date',
            Filter: () => 
                <div className='flex flex-row items-center justify-center w-auto h-8 px-2 shrink-0 text-[rgb(93,93,93)] font-jostBook text-sm '>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={2}
                        name="startDateDay"
                        value={filter.startDateDay > 0?filter.startDateDay:''}
                        autoFocus={focused === 'startDateDay'}
                        placeholder="DD"
                        onChange={onDay}
                        onClick={e => e.stopPropagation()}
                        className='flex w-6 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    />
                    <p className=''>/</p>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={2}
                        name="startDateMonth"
                        value={filter.startDateMonth > 0?filter.startDateMonth:''}
                        autoFocus={focused === 'startDateMonth'}
                        placeholder="MM"
                        onChange={onMonth}
                        onClick={e => e.stopPropagation()}
                        className='flex w-6 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    />
                    <p className=''>/</p>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={4}
                        name='startDateYear'
                        value={filter.startDateYear > 0?filter.startDateYear:''}
                        autoFocus={focused === 'startDateYear'}
                        placeholder='YYYY'
                        onChange={onNumber}
                        onClick={e => e.stopPropagation()}
                        className='flex w-9 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    /> 
                </div>
        },
        {
            name:'enrolled',
            label:'Enrolled',
            Filter: () => 
                <select
                    type="text" 
                    name="enrolled"
                    value={filter.enrolled}
                    autoFocus={focused === 'selfRegistration'}
                    placeholder="Filter..."
                    onChange={onEnrolled}
                    onClick={e => e.stopPropagation()}
                    className='flex h-8 shrink-0 px-1 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                > 
                    <option value=''>All</option>
                    {booleanOptions.map((option,i) => <option key={i} value={option}>{option}</option>)}
                </select>
        }
    ]

    const navigate = useNavigate(); 

    const getUser = async  () => {
        await UserService.getCurrentUser(false)
        .then(async (response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                setUser(response.content);
                let newAllCPDs = [];
                let newEnrolledCPDs = [];
                await getAllCPDs()
                .then((response) => {
                    newAllCPDs = response;
                    setAllCPDs(newAllCPDs);
                }); 
                await getEnrolledCPDs(response.content.moodleId)
                .then((response) => {
                    newEnrolledCPDs = response;
                    setEnrolledCPDs(newEnrolledCPDs);
                });
                doFilter(newAllCPDs,newEnrolledCPDs);
            } else {
                console.log(response);
                navigate('/home');
            }
        })
        .catch((error) => {
            console.log(error.response);
            navigate('/home');
        })
        setReloadUser({reload:true});
    }

    const getAllCPDs = async  () => {
        const cpdsArray = [];
        await CPDService.getCPDs(false)
        .then((response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                for(let cpd of response.content) {
                    cpdsArray.push(
                        {
                            id:cpd.id,
                            shortname:cpd.shortname,
                            fullname:cpd.fullname,
                            startDate: new Date(cpd.startDate*1000) 
                        }
                    );
                }
                
            } else {
                console.log(response);
                navigate('/home');
            }
        })
        .catch((error) => {
            console.log(error.response);
            navigate('/home');
        })
        setReloadUser({reload:true});
        return cpdsArray;
    }

    const getEnrolledCPDs = async  (moodleId) => {
        const cpdsArray = [];
        await CPDService.getEnrolledCPDs(moodleId,false)
        .then((response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                for(let cpd of response.content) {
                    cpdsArray.push(
                        {
                            id:cpd.id,
                            shortname:cpd.shortname,
                            fullname:cpd.fullname,
                            startDate: new Date(cpd.startDate*1000) 
                        }
                    );
                }
            } else {
                console.log(response);
                navigate('/home');
            }
        })
        .catch((error) => {
            console.log(error.response);
            navigate('/home');
        })
        setReloadUser({reload:true});
        return cpdsArray;
    }

    const doFilter = async  (allCPDs,enrolledCPDs) => {
        let cpdsArray = [];
        for(let cpd of allCPDs) {
            const enrolled = enrolledCPDs.find((enrolledCPD) => {return enrolledCPD.id == cpd.id})?'Yes':'No';
            if(filter.id == '' || filter.id == cpd.id) {
                if(filter.shortname == '' || cpd.shortname.toLowerCase().includes(filter.shortname.toLowerCase())) {
                    if(filter.fullname == '' || cpd.fullname.toLowerCase().includes(filter.fullname.toLowerCase())) {
                        if(filter.startDateDay == '' || filter.startDateDay == cpd.startDate.getUTCDate()) {
                            if(filter.startDateMonth == '' || filter.startDateMonth == cpd.startDate.getUTCMonth()+1) {
                                if(filter.startDateYear == '' || filter.startDateYear == cpd.startDate.getUTCFullYear()) {
                                    if(!filter.enrolled || filter.enrolled == '') {
                                        cpdsArray.push({
                                            originalId:cpd.id,
                                            id:'CPD'+cpd.id,
                                            shortname:cpd.shortname,
                                            fullname:cpd.fullname,
                                            startDate:cpd.startDate,
                                            enrolled:enrolled
                                        })
                                    } else if(filter.enrolled.toLowerCase() == enrolled.toLowerCase()) {
                                        cpdsArray.push({
                                            originalId:cpd.id,
                                            id:'CPD'+cpd.id,
                                            shortname:cpd.shortname,
                                            fullname:cpd.fullname,
                                            startDate:cpd.startDate,
                                            enrolled:enrolled
                                        })
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }
        setCPDs(cpdsArray);
        setPageNo(0);
        setPageSize(10);
        setTotalElements(cpdsArray.length);
        setTotalPages(Math.ceil(cpdsArray.length/10));
        setLast(false);
        setReloadUser({reload:true});
    }

    useEffect(() => {
        getUser();
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let height = entry.target.getBoundingClientRect().height;
                calcHeights(height);
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    let timerId;

  return (
        <div ref={mainContainerRef} className='flex flex-col w-full h-full text-sm'>
            <div style={{height:tableContainerHeight + 'px'}}  className='relative flex flex-row w-full'>
                {showArrows &&
                    <div style={{top:'0px',bottom:'0px'}} 
                        className='absolute flex -left-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: -32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.3s linear'
                                }}
                            className={`${true?'visible opacity-100':'invisible opacity-0'} flex w-10 h-10 m-auto bg-[rgb(255,255,255)] rounded-full`}
                        >
                            <LiaAngleLeftSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
                <div className='flex flex-col w-full h-full px-5 overflow-hidden'>
                    <div ref={tableRef} 
                        className='flex flex-col w-full text-sm  no-scrollbar overflow-x-auto overflow-y-hidden'>
                        <div ref={headerRef}
                            style={{height: tHeaderHeight + 'px',transition:'all .5s ease-in-out'}}
                            className='flex flex-row w-fit shrink-0 font-jostBold text-black border-b cursor-pointer'
                            onClick={(e) => {
                                let height = tHeaderHeight === 32?68:32;
                                setTHeaderHeight(height);
                                setTBodyHeight(tableContainerHeight - height);
                                setFocused('');
                            }}
                            onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        doFilter(allCPDs,enrolledCPDs);
                                    }
                                }
                            }>
                            {columns.map((column,i) => 
                                <div key={i} className={column.name+' flex flex-col h-full overflow-hidden'}>
                                    <p className='flex h-8 shrink-0 items-center px-2 text-left whitespace-nowrap'>{column.label}</p>
                                    <div onClick={(e) => e.stopPropagation()} 
                                        className='flex w-fit h-8 shrink-0'>
                                        <column.Filter/>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{height: tBodyHeight + 'px'}}  
                            className='flex w-fit no-scrollbar overflow-x-auto overflow-y-hidden'>
                            {cpds && <Page page={cpds} columns={columns} tableRef={tableRef} headerRef={headerRef} selected={selected} setSelected={setSelected} USDecimal={USDecimal}/>}
                        </div>
                    </div>
                </div>
                {showArrows &&
                    <div style={{top:'0px', bottom:'0px'}} 
                        className='absolute flex -right-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    //setDisableLScrollBtn(false);
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: 32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.5s linear'
                                }}
                            className={`${false?'invisible opacity-0':'visible opacity-100'} flex w-10 h-10 m-auto bg-white rounded-full`}
                        >
                            <LiaAngleRightSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
            </div>
            <div style={{height:messageHeight + 'px'}} className='flex w-full shrink-0 items-center'>
                <p className={`w-full font-jostBook italic text-center text-sm whitespace-nowrap overflow-hidden overflow-ellipsis ${message && !message.success?'text-red-600':'text-green-600'}`}>{message?message.content:''}</p>
            </div>
            <div style={{height:buttonsHeight + 'px'}} className='flex flex-row space-x-4 w-full shrink-0 items-center px-5 text-sm font-jostBook text-[rgb(93,93,93)] bg-[rgb(247,247,247)]'>
                <button  
                    onClick={(e) => {
                        setDialog(null);
                        navigate(`/access/payment/cpd/${selected?selected.originalId:'0'}/${user?user.username:''}/1`);
                    }}
                    disabled={!(selected && selected.enrolled.toLowerCase() == 'no')}
                    className='flex flex-col items-center justify-between w-auto h-16 shrink-0 text-xs whitespace-nowrap capitalize'>
                    <div className={`flex w-10 h-10 items-center justify-center shrink-0 ${(selected && selected.enrolled.toLowerCase() == 'no')?'bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)]':'bg-[rgba(0,175,240,.5)]'} text-white shadow-md rounded-lg`}>
                        <LiaMoneyBillWaveSolid size={32}/>
                    </div>
                    Enroll
                </button>
                <button 
                    onClick={(e) => {}} 
                    className='flex flex-col items-center justify-between w-auto h-16 shrink-0 text-xs whitespace-nowrap capitalize'>
                    <div className='flex w-10 h-10 items-center justify-center shrink-0 bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)] shadow-md rounded-lg'>
                        <PiPrinter size={32}/>
                    </div>
                    Print Statement
                </button>
            </div> 
            {deposit &&
                <div className='fixed flex top-0 left-0 w-screen h-screen items-center justify-center'>
                    {deposit}
                </div>
            }       
        </div>  
  )
}

const Page = ({page,columns,tableRef,headerRef,selected,setSelected,USDecimal}) => {
    const pagesRef = useRef(null);
    function getTextWidth(text,font,fontSize) { 
        let span = document.createElement("span"); 
        document.body.appendChild(span); 
        span.style.fontFamily = font; 
        span.style.fontSize = fontSize + "px"; 
        span.style.height = 'auto'; 
        span.style.width = 'auto'; 
        span.style.position = 'absolute'; 
        span.style.whiteSpace = 'no-wrap'; 
        span.innerHTML = text; 
     
        let width = Math.ceil(span.clientWidth);   
        document.body.removeChild(span); 
        return width;
    } 

    useEffect(() => {
        if(!pagesRef.current) {
            return;
        }
        if(!tableRef.current) {
            return;
        }
        if(!headerRef.current) {
            return;
        }
        let columnWidths = [];
        let totalWidth = 0;
        for(let column of columns) {
            const columnHeader = headerRef.current.getElementsByClassName(column.name)[0];
            if(!columnHeader) {
                continue;
            }
            const columnLabel = columnHeader.getElementsByTagName('p')[0];
            const columnFilter = columnHeader.getElementsByTagName('div')[0];
            let width = 0;
            if(columnLabel){
                width = getTextWidth(columnLabel.textContent,'Jost-700-Bold',14)+16;
            }
            if(columnFilter){
                const filterWidth = columnFilter.getBoundingClientRect().width;
                width = filterWidth > width?filterWidth:width;  
            }
            const elements = pagesRef.current.getElementsByClassName(column.name);
            for(let element of elements){
                let w = getTextWidth(element.textContent,'Jost-400-Book',14)+16;
                if(w > width){
                    width = w;
                }
            }
            
            columnHeader.style.width = width+'px';
            for(let element of elements){
                element.style.width = width+'px';
            }
            columnWidths.push(width);
            totalWidth += width;
        }
        const tableWidth = tableRef.current.getBoundingClientRect().width;
        if(totalWidth < tableWidth) {
            for(let i = 0; i < columns.length; i++) {
                const column = columns[i];
                const colWidth = columnWidths[i];
                if(colWidth) {
                    let width = colWidth/totalWidth * tableWidth;
                    const columnHeader = headerRef.current.getElementsByClassName(column.name)[0];
                    columnHeader.style.width = width+'px';
                    const elements = pagesRef.current.getElementsByClassName(column.name);
                    for(let element of elements){
                        element.style.width = width+'px';
                    }
                }
             }
        }
    },[page]);

    return (
        <div ref={pagesRef} className='flex flex-col w-fit h-full shrink-0 no-scrollbar overflow-y-auto'>
            {page.map((entry,i) =>
                <div key={i}
                onClick={(e) => setSelected(entry)}
                    className={`flex flex-row w-fit h-10 shrink-0 items-center font-jostBook cursor-pointer ${selected && selected.id == entry.id?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'}`}>
                    {columns.map((column,i) => 
                        <div key={i} 
                            style={{textAlign:typeof entry[column.name] === 'number'?'right':'left'}}
                            className={column.name+' w-fit start-0 px-2 whitespace-nowrap'}>
                            {entry[column.name] instanceof Date?entry[column.name].getUTCDate()+'/'+(entry[column.name].getUTCMonth()+1)+'/'+entry[column.name].getUTCFullYear():
                            typeof entry[column.name] === 'boolean'?entry[column.name]?'Yes':'No':
                            typeof entry[column.name] === 'number'?USDecimal.format(entry[column.name]):
                            entry[column.name]}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CPDTable