import React, {useEffect,useState,useRef,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useLocation } from 'react-router-dom';
import {professionalCategories} from '../data'
import { PiCertificateLight } from "react-icons/pi";
import { LiaCertificateSolid } from "react-icons/lia";
import { GiSpeedometer } from "react-icons/gi";
import { LiaHospitalSolid } from "react-icons/lia";
import { BsPrescription } from "react-icons/bs";
import { LiaFileSignatureSolid } from "react-icons/lia";
import { GrUserAdmin } from "react-icons/gr";
import { GoDotFill } from "react-icons/go";

const ProfessionalCategories = () => {
    const {mainElementRef,findMenu} = useContext(GlobalContext);
    const navigate = useNavigate();
    const path = useLocation().pathname;
    const [imgHeight,setImgHeight] = useState(0);
    const [content,setContent] = useState(null);
    const textRef = useRef(null);

    if(mainElementRef.current) {
        mainElementRef.current.scrollTo({top: 0});
    }

    useEffect(() => { 
        let currentItem = findMenu(professionalCategories,path);
        if(!currentItem) {
            navigate('/home');
        }

        if(currentItem) {
            if(currentItem.menus) {
                navigate(currentItem.menus[0].link);
            }
    
            setContent(currentItem);
        }

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let rect = entry.target.getBoundingClientRect();
                setImgHeight(rect.height + 166);
            }
        });

        if(textRef.current) {
            observer.observe(textRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[path]);

    return (
        <div className='relative flex flex-col w-full h-auto bg-[rgb(243,244,245)]'>
          <div style={{height: imgHeight+'px'}}  className='relative flex flex-col w-full shrink-0 overflow-hidden'>
            <img src='/images/img_1.jpg' className='flex w-full h-full object-cover object-top overflow-hidden'/>
            <div className='absolute flex  left-0 top-0 w-full h-full shrink-0 bg-gradient-to-b from-[rgba(0,0,0,.8)] via-transparent to-[rgba(0,0,0,.8)]'/>
            <div ref={textRef} className='absolute flex flex-col space-y-4 items-center justify-center top-[166px] w-full h-auto'>
              <div className='flex w-[1px] h-24 border-l border-white'></div>
              <p className='text-white text-center tracking-wide text-4xl lg:text-7xl font-leBeauneNew'> 
                  professional categories
              </p>
              <div className='flex w-[1px] h-24 border-l border-white'></div>
            </div>
          </div>
          <div className='relative flex flex-col w-full h-auto items-center shrink-0 mb-24'>
            <div className='flex w-[2px] h-16 border-r border-[rgb(204,204,204)]'/>
            <p className='w-full h-auto mt-8 text-4xl text-center text-[rgb(50,50,50)] font-leBeauneNew'>
              {content && content.name}
            </p>
          </div>
          <div className='relative flex flex-col w-full h-auto space-y-8 px-[10%] py-16 shrink-0 text-[rgb(85,85,85)] bg-[rgb(238,238,238)]'>
            {content && !content.name.toLowerCase().includes('specialized')?
                <>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <PiCertificateLight size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left tracking-wider font-jostSemi'>
                            Qualification:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.qualification}
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <LiaCertificateSolid size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider font-jostSemi capitalize'>
                            Zambia Qualification Authority:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.zambiaQualificationAuthority}      
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <GiSpeedometer size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider font-jostSemi capitalize'>
                            Minimum credit requirement:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook '>
                            {content && content.minimumCreditRequirement}
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <LiaHospitalSolid size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider whitespace-nowrap font-jostSemi capitalize'>
                            Area of practice:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.areaOfPractice}
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <BsPrescription size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider whitespace-nowrap font-jostSemi capitalize'>
                            Limitations in drug Prescription:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.limitationInDrugPrescription}
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <LiaFileSignatureSolid size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider font-jostSemi capitalize'>
                            Limitation in Statutory and legal document signatory:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.limitationInStatutoryAndLegalDocumentSignatory}
                        </p>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-x-8 items-center'>
                        <GrUserAdmin size={64} className='shrink-0'/>
                        <p className='lg:w-96 text-lg text-center lg:text-left shrink-0 tracking-wider font-jostSemi capitalize'>
                            Administration:
                        </p>
                        <p className='text-lg text-center lg:text-left tracking-wider font-jostBook'>
                            {content && content.administration} 
                        </p>
                    </div>
                </>
                :
                <div className='flex flex-col w-full h-auto space-y-8'>
                    <PiCertificateLight size={128} className='flex mx-auto shrink-0'/>
                    <p className='text-xl shrink-0 tracking-wider font-jostSemi capitalize'>
                        MSc clinical Ophthalmology 
                    </p>
                    <p className='text-xl shrink-0 tracking-wider font-jostSemi capitalize'>
                        Master of public health
                    </p>
                    <p className='text-xl shrink-0 tracking-wider font-jostSemi capitalize'>
                        Master of Science in Infectious diseases 
                    </p>
                    <p className='text-xl shrink-0 tracking-wider font-jostSemi capitalize'>
                        The Association is advocating for; 
                    </p>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            Master of science in Clinical Medicine – Tropical Medicine
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            Master of Science in Clinical Medicine – Anesthesia and critical care
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            Master of Science in Clinical Medicine – Dermatovenerology
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            Master of clinical medicine – Community psychiatry and mental health
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            MSc Clinical Medicine – ENT
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            MSC Clinical Medicine – Cancer and Clinical Oncology
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4 items-center'>
                        <GoDotFill size={16} className='shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook'>
                            MSc Clinical Ophthalmology
                        </p>
                    </div>
                </div>
            }
          </div>
        </div>
      )
}

export default ProfessionalCategories