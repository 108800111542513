import React, {useState,useEffect,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import {getSettings} from '../data';
import { LiaAngleRightSolid } from "react-icons/lia";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate,useParams} from 'react-router-dom';
import UserService from '../services/UserService';

const Settings = () => {
    const {hasAuthority,setReloadUser,mobileMode} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const navigate = useNavigate();
    const [selected,setSelected] = useState(null);
    const [settings,setSettings] = useState([]);
    const [menuOpened,setMenuOpened] = useState(false);
    const {setting,item} = useParams();

    useEffect(() => { 
        ( async () => {
            await UserService.getCurrentUser(false)
            .then((response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content && response.content.role) {
                    setUser(response.content);
                    let newSettings = getSettings(response.content,hasAuthority).menus;
                    setSettings(newSettings);
                    if(setting) {
                        for(let newSetting of newSettings) {
                            if(newSetting.name.toLowerCase() === setting.toLowerCase()) {
                                setSelected(newSetting);
                                break;
                            }
                        }
                    } else {
                        newSettings && newSettings.length > 0 && setSelected(newSettings[0]);
                    }
                }  else {
                    navigate('/home');
                }
            })
            .catch((error) => {
                console.log(error.message);
                navigate('/home');
            })
            setReloadUser({reload:true});
        }
        )(); 
    },[setting,item]);

    return (
        <div className='relative flex flex-col w-full h-auto'>
            <div className='relative flex flex-col w-full h-[50vh] shrink-0 overflow-x-hidden overflow-y-auto'>
                <img src='/images/bg_13.jpg' className='flex top-0 right-0 h-full object-cover overflow-hidden'/>
                <div className='absolute flex  left-0 top-0 w-full h-full shrink-0 bg-gradient-to-b from-[rgba(0,0,0,.5)] via-transparent to-[rgba(0,0,0,.5)]'/>
                <div className='absolute flex flex-col-reverse space-y-reverse space-y-4 justify-center left-[7vw] bottom-0 w-full'>
                    <div className='flex max-w-[60%] w-auto h-24 border-l border-white'> 
                    </div>
                    <p className='text-white text-4xl lg:text-7xl font-leBeauneNew tracking-wide'> 
                        Settings
                    </p>
                </div>
            </div>
            <section
                onClick={(e) => {
                    e.preventDefault();
                    setMenuOpened(false);
                }}
                className='relative flex flex-col w-full h-auto shrink-0 bg-white'>
                <div className='flex w-[2px] h-24 ml-[7vw] shrink-0 border-r border-[rgb(204,204,204)]'/>
                <div 
                    className='relative flex h-auto mx-[7vw] pl-[1px]  overflow-hidden lg:overflow-visible'>
                    <div style={{transition:'all .5s ease-in-out'}}
                        className={`absolute lg:static flex flex-col w-[80%] max-w-sm lg:w-1/4 top-0 bottom-0 lg:h-full py-8 space-y-4 z-20 lg:z-auto  ${menuOpened?'left-[1px] shadow-xl':'-left-[80%]'} border-l border-[rgb(204,204,204)] bg-[rgb(238,238,238)]`}>
                        {settings && settings.map((setting,i) =>
                            <button key={i} 
                                onClick={(e) => setSelected(setting)} 
                                className={`flex flex-row w-full h-10 px-8 space-x-2 items-center ${selected && selected.name === setting.name?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'}`}>
                                <setting.Icon size={32} fill={selected && selected.name === setting.name?'rgb(0,175,240)':'rgb(85,85,85)'}/>
                                <div className='tracking-wider font-jostBook capitalize'>{setting.name}</div>
                            </button>
                        )}
                    </div>
                    <div className='flex flex-col w-full lg:w-3/4 h-auto bg-[rgb(252,252,252)] border-l lg:border-none border-[rgb(204,204,204)]'>
                        <div className='flex flex-row w-full items-center px-5 h-10 shrink-0 text-[rgb(150,150,150)] text-sm font-jostBook tracking-wider bg-[rgb(247,247,247)]'>
                            {mobileMode?
                                <div className='relative flex flex-row justify-between items-center w-full'>
                                    <button 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setMenuOpened(true);
                                    }}
                                    className='absolute left-5 z-10'>
                                        <AiOutlineMenu size={32}/>
                                    </button>
                                    <p className='absolute text-[rgb(0,175,240)] w-full text-center capitalize'>
                                        {selected && selected.name}
                                    </p>
                                </div>
                                :
                                <div className='flex flex-row space-x-2 items-center w-full'>
                                    <p>Settings</p>
                                    <LiaAngleRightSolid size={14}/>
                                    <p className='text-[rgb(0,175,240)] capitalize'>
                                        {selected && selected.name}
                                    </p>
                                </div>
                            }   
                        </div>
                        <div className='flex w-full h-auto min-h-[400px]'>
                            {selected && (<selected.Component user={user}/>)}
                        </div>
                    </div>
                </div>
                <div className='flex w-[2px] h-10 ml-[7vw] mb-14 shrink-0 border-r border-[rgb(204,204,204)]'/>
            </section>
        </div>
      )
}

export default Settings