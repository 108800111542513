import React, {useState,useEffect,useContext, useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import {association} from '../data'
import { BsArrowRight,BsArrowLeft } from "react-icons/bs";
import { TbTargetArrow } from "react-icons/tb";
import { FaListCheck } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { LiaUserNurseSolid,LiaUserTieSolid,LiaUsersSolid,LiaBuilding,LiaClockSolid } from "react-icons/lia";
import { MdArrowRightAlt } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { NavLink,useNavigate } from 'react-router-dom';
import EventService from '../services/EventService';
import CountDown from './CountDown';

const Home = () => {
  const [welcomeImgHeight,setWelcomeImgHeight] = useState(0);
  const {reloadUser,setReloadUser} = useContext(GlobalContext);
  const welcomeTextRef = useRef(null);

  const navigate = useNavigate();

  const images = [
    '/images/img_3.jpg',
    '/images/img_1.jpg',
    '/images/img_5.jpg',
    '/images/img_15.jpg'
  ]

  useEffect(() => {
      setReloadUser({reload:true});
      const observer = new ResizeObserver(entries => {
          for (let entry of entries) {
              let rect = entry.target.getBoundingClientRect();
              setWelcomeImgHeight(rect.height + 166);
          }
      });

      if(welcomeTextRef.current) {
          observer.observe(welcomeTextRef.current)
      }
      return () => {
          observer.disconnect();
      }
  },[]);

  return (
    <div className='relative flex flex-col w-full h-auto bg-[rgb(243,244,245)]'>
      <div style={{height: welcomeImgHeight+'px'}} className='relative flex flex-col w-full shrink-0 overflow-hidden'>
        <img src='/images/img_45.jpg' className='flex top-0 w-full h-full object-cover'/>
        <div className='absolute flex  left-0 top-0 w-full h-full shrink-0 bg-gradient-to-b from-[rgba(0,0,0,.5)] via-transparent to-[rgba(0,0,0,.5)]'/>
        <div ref={welcomeTextRef} className='absolute flex flex-col space-y-4 items-center justify-center top-[166px] w-full h-auto'>
          <div className='flex w-[1px] h-8 shrink-0 border-l border-white'></div>
          <p className='text-white tracking-wide whitespace-nowrap text-xl font-leBeauneNew'> 
            WELCOME TO
          </p>
          <p className='h-[128px] text-white text-9xl font-leBeauneNew tracking-wide'> 
            COAZ
          </p>
          {/* <p className='text-white tracking-wide text-center text-2xl font-leBeauneNew'> 
            THE CLINICAL OFFICERS' ASSOCIATION OF ZAMBIA
          </p>
          <p className='flex w-fit px-8 text-white text-xl text-center font-jostMedium tracking-wide'> 
            Advocating for professional, excellence and community involvement
          </p> */}
          <CountDown title={'the 8th annual general meeting'} date={new Date(Date.UTC(2024,9,14,8,0,0))}/>
          <button style={{transition:'all 1s ease-in-out'}}
            onClick={(e) => {
                EventService.eventRegistration('agm')
                .then((response) => {
                    navigate(response);
                })
                .catch((error) => {
                  console.log(error.message);
                })
            }}
            className='flex flex-row w-auto h-10 px-2 items-center bg-[rgb(0,175,240)] text-[rgb(243,244,245)] hover:bg-[rgb(243,244,245)] hover:text-[rgb(0,175,240)] text-ms font-jostMedium uppercase'>
            Register Now
            <MdArrowRightAlt size={22} className='ml-2'/>
          </button>
          <div className='flex w-[1px] h-8 mx-auto border-r border-white'></div>
        </div>
      </div>
      <section className='relative flex flex-col w-full h-auto shrink-0'>
          <div className='flex w-[2px] h-20 mx-auto border-r border-[rgb(204,204,204)]'></div>
          <p style={{fontSize:42+'px',lineHeight:52+'px'}} className='flex w-[90%] lg:w-[600px] h-auto mt-8 mx-auto text-center text-[rgb(50,50,50)] font-leBeauneNew'>
            THE CLINICAL OFFICERS' ASSOCIATION OF ZAMBIA
          </p>
          <p style={{fontSize:'18px',lineHeight:'32px'}} className='w-[90%] lg:w-[700px] h-auto mt-8 mx-auto font-jostBook text-center text-[rgb(85,85,85)]'>
            Advocating for professional, excellence and community involvement
          </p>
          <NavLink to={'/about'}>
            <button className='flex w-auto h-8 px-2 my-20 mx-auto items-center font-jostMedium text-sm tracking-wide text-[rgb(0,175,240)] border border-[rgb(0,175,240)]'>
              LEARN MORE
            </button>
          </NavLink>
      </section>
      <section className='relative flex flex-col w-full h-auto shrink-0'>
          <News/>
      </section>
      <section className='relative flex flex-col w-full h-auto shrink-0'>
          <Events/>
      </section>
      <section className='relative flex flex-col w-full h-auto shrink-0'>
          <Association/>
      </section>
      <section className='relative flex flex-col space-y-8 w-full h-auto lg:h-[75vh] mb-24 p-10 shrink-0 bg-[url(/public/images/img_27.jpg)] bg-center bg-cover'>
          <div className='flex flex-col space-y-8 items-center justify-center w-full lg:w-1/2 h-auto m-auto'>
              <p className='text-center text-lg font-[arial] text-white'>
                WE WELCOME YOU TO LEARN MORE ABOUT OUR PROCESSES
              </p>
              <p className='text-center text-4xl text-white font-leBeauneNew'>
                WE'RE ALWAYS HAPPY TO HEAR FROM YOU
              </p>
              <button className='flex items-center justify-center w-44 h-14 m-auto lg:mt-16 shrink-0 bg-[rgb(0,175,240)] text-white text-xs font-jostSemi tracking-wider rounded-sm'>
                CONTACT US
              </button>
          </div>
      </section>
      <section className='relative flex flex-col w-full h-auto lg:h-[70vh] mt-16 shrink-0 '>
          <div className='flex h-24 mx-auto shrink-0 border-l border-[rgb(204,204,204)]'></div>
          <p style={{fontSize:42+'px',lineHeight:52+'px'}} className='text-center w-auto h-auto px-8 mx-auto pt-2 text-[rgb(50,50,50)] font-leBeauneNew'>
              OUR STATISTICS
          </p>
          <div className='flex w-full h-auto mt-16 lg:h-full bg-[url(/public/images/img_28.jpg)] bg-center bg-cover'>
            <div className='flex flex-col lg:flex-row w-full h-auto lg:h-full py-8 items-center justify-center bg-[rgba(0,0,0,.3)]'>
                <div className='flex flex-col items-center w-auto lg:w-1/4 h-auto mb-8 lg:mb-0'>
                  <LiaUserNurseSolid size={96} className='text-[rgb(226,230,234)]'/>
                  <p className='w-full text-center text-white font-leBeauneNew text-[58px] lg:border-r border-[rgb(226,230,234)]'>
                    1200+
                  </p>
                  <p className='text-center text-[24px] text-[rgb(0,175,240)] font-[arial] font-medium'>MEMBERS</p>
                </div>
                <div className='flex flex-col items-center w-auto lg:w-1/4 h-auto mb-8 lg:mb-0'>
                  <LiaUserTieSolid size={96} className='text-[rgb(226,230,234)]'/>
                  <p className='w-full text-center text-white font-leBeauneNew text-[58px] lg:border-r border-[rgb(226,230,234)]'>
                    100+
                  </p>
                  <p className='text-center text-[24px] text-[rgb(0,175,240)] font-[arial] font-medium'>STAFF</p>
                </div>
                <div className='flex flex-col items-center w-auto lg:w-1/4 h-auto mb-8 lg:mb-0'>
                  <LiaUsersSolid size={96} className='text-[rgb(226,230,234)]'/>
                  <p className='w-full text-center text-white font-leBeauneNew text-[58px] lg:border-r border-[rgb(226,230,234)]'>
                    4+
                  </p>
                  <p className='text-center text-[24px] text-[rgb(0,175,240)] font-[arial] font-medium'>PARTNERS</p>
                </div>
                <div className='flex flex-col items-center w-auto lg:w-1/4 h-auto'>
                  <LiaBuilding size={96} className='text-[rgb(226,230,234)] font-thin'/>
                  <p className='w-full text-center text-white font-leBeauneNew text-[58px]'>
                    116+
                  </p>
                  <p className='text-center text-[24px] text-[rgb(0,175,240)] font-[arial] font-medium'>DISTRICTS</p>
                </div>
            </div>
          </div>
      </section>
      <section className='relative flex flex-col lg:flex-row items-center justify-between w-full h-auto px-8 lg:px-[5%] py-16 shrink-0'>
        <img src='/images/HPCZ-GRAY.png' className='w-64 h-32 mb-32 lg:mb-0 object-contain'/>
        <img src='/images/LOGO-HEA-3-GRAY.png' className='w-64 h-32 mb-32 lg:mb-0 object-contain'/>
        <img src='/images/MOH-GRAY2.png' className='w-64 h-32 mb-32 lg:mb-0 object-contain'/>
        <img src='/images/local_authority.png' className='w-64 h-32 object-contain'/>
      </section>
    </div>
  )
}

const Association = () => {
  const [columns,setColumns] = useState(association.menus? association.menus.length:0);
  const [itemSize,setItemSize] = useState({});
  const associationRef = useRef(null)

  const calWidth = (pw,mw,pa,sp,cols) => {
      if(cols === 0){
        return {w:0,cols:0};
      }
      let w = 0;
      let aw = pw - (pa*2) - (sp * (cols - 1));
      w = aw/cols;
      if(w < mw && cols > 1) {
          cols -= 1;
          return calWidth(pw,mw,pa,sp,cols)
      } else {
        return {w,cols};
      }
  }

  useEffect(() => {
      const observer = new ResizeObserver(entries => {
          for (let entry of entries) {
              let minWidth = 450;
              let paddingX = 32;
              let spaceX = 32;
              let parentWidth = entry.target.getBoundingClientRect().width;
              let {w,cols} = calWidth(parentWidth,minWidth,paddingX,spaceX,columns); 
              let h = (449*w)/640;
              setItemSize({width:w,height:h});
              setColumns(cols);
          }
      });

      if(associationRef.current) {
          observer.observe(associationRef.current)
      }
      return () => {
          observer.disconnect();
      }
  },[]);

  return (
    <div ref={associationRef} 
        style={{background:'linear-gradient(to bottom, rgba(0,0,0,.0) 0, rgba(0,0,0,.0) 128px, rgb(238,238,238) 128px,rgb(238,238,238) 100%)'}}
        className='flex flex-col space-y-8 px-8 w-full h-auto pb-24 bg-[rgb(238,238,238)]'>
        {association.menus && (
          (() => {
              const rows = [];
              let rowKey = 0;
              for (let i = 0; i < association.menus.length;) {
                const row = [];
                for (let j = 0; j < columns; j++) {
                    if(i < association.menus.length) {
                        row.push(<Item key={i} item={association.menus[i]} size={itemSize}/>);
                        i++;
                    } else {
                        break;
                    }
                }
                rows.push(<div key={rowKey} className={`flex flex-row w-auto h-auto shrink-0 space-x-8`}>{row}</div>);
                rowKey++;
              }
              return rows;
          })()
        )}
    </div>
  )
}
const Item = ({item,size}) => {
  return (
    <div style={{width:size.width+'px'}} className='flex flex-col h-auto shrink-0 overflow-hidden'>
        <img src={item.image} alt={size.width+'x'+size.height} 
          style={{width:size.width+'px',height:size.height+'px'}}
          className='flex object-cover'
        />
        <div className='flex flex-col w-full h-auto pt-8 space-y-6'>
          <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] text-[rgb(59,59,59)]'>{item.name}</p>
          <p style={{display:'-webkit-box', WebkitBoxOrient:'vertical',WebkitLineClamp:'2'}} 
            className=' w-full text-lg text-[rgb(100,100,100)] font-jostBook overflow-hidden overflow-ellipsis'>
            {item.content}
          </p>
          <NavLink to={item.link}>
            <button
              className='w-24 h-8 my-8 font-jostSemi tracking-wide text-sm text-white bg-[rgb(0,175,240)]'>
              READ MORE
            </button>
          </NavLink>
          
        </div>
    </div>
  )
}

const News = () => {
  const {mobileMode} = useContext(GlobalContext);
  const  newsItems = [
    {
      image:'/images/img_35.jpg',
      date:'April 15, 2024',
      heading:'delivering care',
      content:'COAZ is dedicated to promoting excellence in professional practice within the healthcare sector. We provide resources, guidelines, and support to empower our member healthcare professionals to deliver high-quality care to their patients. Our focus is enhancing a culture of continuous improvement and innovation in healthcare delivery'
    },
    {
      image:'/images/img_36.jpg',
      date:'April 15, 2024',
      heading:'Professional practice',
      content:'Patients in Primary Health care facilities deserve care led by Clinical practioners—the most highly educated, trained and skilled health care professionals at such levels. Through research, advocacy and education, the COAZ vigorously defends the Clinical practice against scope of practice expansions that threaten patient safety'
    },
    {
      image:'/images/img_37.jpg',
      date:'April 15, 2024',
      heading:'Advocacy in Health Care',
      content:'At the state level, the COAZ shapes the policy of influential national policymaking, partners with outside experts and stakeholder groups and influences the enactment and/or defeat of state legislation and regulation'
    },
    {
      image:'/images/img_40.jpg',
      date:'April 15, 2024',
      heading:'Member Benefits',
      content:'Joining the nation’s largest Health professional Organization in Zambia has considerable benefits. In addition to opportunities to shape the future of health care, COAZ membership benefits include exclusive access to savings and resources designed to enhance the personal and professional lives of Members'
    }
  ]

  const newsRef = useRef(null);
  const newsSliderRef = useRef(null);
  const bgImgRef = useRef(null);
  const [bgImgHeight,setBgImgHeight] = useState(0); 
  const [index,setIndex] = useState(0);
  const [slideWidth,setSlideWidth] = useState(0);
  const [newsImgHeight,setNewsImgHeight] = useState(0);
  const [visibleSlides,setVisibleSlides] = useState(0);
  const newsHeaderHeight = 240;
  const newsContentHeight = 230;
  const bulletsHeight = 96;
  const slideShadowAllow = 100;
  const space = 32;

  const slide = (newIndex) => {
      if(newsSliderRef.current) {
          let slides = newsSliderRef.current.getElementsByClassName('news-slide');
          if(slides) {
              let rect = newsSliderRef.current.getBoundingClientRect();
              let width = (slideWidth);
              let spaceX = (space);
              let origin = spaceX;
              let left = origin - ((newIndex*width)+(newIndex*spaceX));
              for (let i = 0;i < slides.length; i++) {
                let slide = slides[i];
                slide.style.left = left+'px';
                left += width + spaceX;
              }
              setIndex(newIndex);
          }
      }
  }

  const calcSlideWidth = (w,sp,mw,vi,mv) => {
    if(vi === 0){
      return {wi:0,vi:0};
    }
    let av = w - (sp* (vi+1));
    let wi = av/vi;
    if((wi < mw && vi > 1) || vi > mv) {
        vi -= 1;
        return calcSlideWidth(w,sp,mw,vi,mv);
    } else {
      return {wi,vi};
    }
  }

  useEffect(() => {
      if(bgImgRef.current) {
          let bgImgWidth = bgImgRef.current.getBoundingClientRect().width;
          setBgImgHeight(bgImgWidth*0.65);
      }
      
      let i = 0;
      const interval = setInterval((e) => {
          slide(i);
          i++
          if(i >= newsItems.length - (visibleSlides - 1)) {
            i = 0;
          }
      },10000);

      const observer = new ResizeObserver(entries => {
          for (let entry of entries) {
              let rect = entry.target.getBoundingClientRect();
              let minWidth = 200;
              let maxVisibleItems = 2;
              let {wi,vi} = calcSlideWidth(rect.width,space,minWidth,newsItems.length,maxVisibleItems);
              setSlideWidth(wi);
              setVisibleSlides(vi);
              setNewsImgHeight(wi*0.64);
              slide(0);
          }
      });

      if(newsSliderRef.current) {
          observer.observe(newsSliderRef.current)
      }
      return () => {
          clearInterval(interval);
          observer.disconnect();
      };
  },[slideWidth]);

  return (
    // height = slider padding(top-80=320px) top+slider-height(newsImgHeight+newsContentHeight+100+'px')+bullets(h-24=96px)
    <div ref={newsRef} 
        style={{'--height':newsHeaderHeight+newsImgHeight+newsContentHeight+slideShadowAllow+bulletsHeight+'px'}} 
        className='relative flex flex-col h-[var(--height)] w-full bg-white'>
        <div ref={bgImgRef} style={{'--bg-img-height':newsHeaderHeight+newsImgHeight+'px','--padding-left':space+'px'}}
            className='flex flex-col w-full h-[var(--bg-img-height)] pt-16 pl-[var(--padding-left)] bg-[rgb(238,238,238)]'>
            <p style={{fontSize:42+'px',lineHeight:52+'px'}}
              className='flex lg:w-1/2 pt-2 text-[rgb(50,50,50)] font-leBeauneNew uppercase'>
              VISIT OUR INSIGHTS AND LATEST NEWS
            </p>
        </div>
        <div ref={newsSliderRef}
          style={{top:newsHeaderHeight+'px','--slider-height':newsImgHeight+newsContentHeight+slideShadowAllow+'px'}} 
          className='absolute flex flex-row w-full h-[var(--slider-height)] overflow-hidden'>
          {newsItems && (newsItems.map((newsItem,i) => 
            <div key={i} style={{'--slide-width':slideWidth+'px',transition:'left 1s ease-in-out',boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
                className='news-slide absolute flex flex-col top-8 w-[var(--slide-width)] h-auto bg-white'>
                <img 
                  src={newsItem.image}
                  style={{'--img-height':newsImgHeight+'px'}} 
                  className='w-full h-[var(--img-height)] object-cover'
                />
                <div style={{'--content-height':newsContentHeight+'px'}} 
                  className='flex flex-col w-full h-[var(--content-height)] p-8'
                >
                  <p className='text-xs text-[rgb(180,180,180)] font-jostSemi tracking-wide'>{newsItem.date}</p>
                  <p style={{display:'-webkit-box', WebkitBoxOrient:'vertical',WebkitLineClamp:'2'}} 
                    className='flex w-full h-auto pt-4 lg:text-lg text-[rgb(59,59,59)] font-semibold font-leBeauneNew overflow-hidden overflow-ellipsis'>
                      {newsItem.content}
                  </p>
                  <button className='flex w-fit my-8 font-jostSemi tracking-wide text-xs text-[rgb(0,175,240)]'>
                    READ MORE
                  </button>
                </div>
            </div>
            )
          )}
        </div>
        <div style={{'--bullets-height':bulletsHeight+'px'}}
          className='absolute flex flex-row space-x-2 items-center justify-center bottom-0 w-full h-[var(--bullets-height)]'>
        {newsItems && (
          (() => {
            let bullets = [];
            for(let i = 0;i < newsItems.length - (visibleSlides - 1);i++) {
              bullets.push(<Bullet key={i} index={i} currentIndex={index} slide={slide}/>);
            }
            return bullets;
          })()
        )}
      </div>
    </div>
  )
}

const Bullet = ({index,currentIndex,slide}) => {
  return (
      <div onClick={(e) => slide(index)}
          style={{transition:'all 1s ease-in-out'}} 
          className={`flex w-2 h-2 z-20 rounded-full ${currentIndex === index?'bg-[rgb(0,175,240)]':'bg-[rgb(223,223,223)]'} cursor-pointer`}
      >
      </div>
  )
}

const Events = () => {
  const {mobileMode} = useContext(GlobalContext);
  const  events = [
    {
      image:'/images/img_38.jpg',
      date:'April 16, 2024',
      title:'annual general meeting',
      start:'7:00 am',
      end:'10:00 am',
      venue:'Coaz headquoters boardroom'
    },
    
    {
      image:'/images/img_21.jpg',
      date:'April 18, 2024',
      title:'the vice president will be visiting our headquoter offices in lusaka',
      start:'8:00 am',
      end:'10:00 am',
      venue:'Coaz headquoters'
    },
    {
      image:'/images/img_41.jpg',
      date:'April 20, 2024',
      title:"Appointment of the association's president",
      start:'8:00 am',
      end:'10:00 am',
      venue:'Coaz headquoters boardroom'
    },
    {
      image:'/images/img_39.jpg',
      date:'April 22, 2024',
      title:'welcoming of the new president',
      start:'10:00 am',
      end:'1:00 pm',
      venue:'Coaz headquoters main hall'
    }
  ]

  const eventsSliderRef = useRef(null);
  const bgImgRef = useRef(null);
  const [bgImgHeight,setBgImgHeight] = useState(0); 
  const [index,setIndex] = useState(0);
  const [slideWidth,setSlideWidth] = useState(0);
  const [eventImgHeight,setEventImgHeight] = useState(0);
  const [height,setHeight] = useState(0);
  const [visibleSlides,setVisibleSlides] = useState(0);
  const eventContentHeight = 210;
  const eventsHeaderHeight = 256;
  const bulletsHeight = 96;
  const slideShadowAllow = 100;
  const space = 32;

  const slide = (newIndex) => {
    if(eventsSliderRef.current) {
        let slides = eventsSliderRef.current.getElementsByClassName('event-slide');
        if(slides) {
            let width = (slideWidth);
            let spaceX = (space);
            let origin = spaceX;
            let left = origin - ((newIndex*width)+(newIndex*spaceX));
            for (let i = 0;i < slides.length; i++) {
              let slide = slides[i];
              slide.style.left = left+'px';
              left += width + spaceX;
            }
            setIndex(newIndex);
        }
    }
  }

  const calcSlideWidth = (w,sp,mw,vi,mv) => {
    if(vi === 0){
      return {wi:0,vi:0};
    }
    let av = w - (sp* (vi+1));
    let wi = av/vi;
    if((wi < mw && vi > 1) || vi > mv) {
        vi -= 1;
        return calcSlideWidth(w,sp,mw,vi,mv);
    } else {
      return {wi,vi};
    }
  }

  useEffect(() => {
    if(bgImgRef.current) {
        let bgImgWidth = bgImgRef.current.getBoundingClientRect().width;
        setBgImgHeight(bgImgWidth*0.65);
    }

    let i = 0;
    const interval = setInterval((e) => {
      slide(i);
      i++
      if(i >= events.length - (visibleSlides - 1)) {
        i = 0;
      }
    },10000);

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
          let rect = entry.target.getBoundingClientRect();
          let minWidth = 200;
          let maxVisibleItems = 3;
          let {wi,vi} = calcSlideWidth(rect.width,space,minWidth,events.length,maxVisibleItems);
          setSlideWidth(wi);
          setVisibleSlides(vi);
          setEventImgHeight(wi*0.64)
          slide(0);
      }
    });

    if(eventsSliderRef.current) {
        observer.observe(eventsSliderRef.current)
    }

    return () => {
        clearInterval(interval);
        observer.disconnect();
    };
  },[slideWidth]);

  return (
    <div id='events' 
      style={{'--height':eventsHeaderHeight+eventImgHeight+eventContentHeight+slideShadowAllow+bulletsHeight+'px'}}
      className='relative flex flex-col w-full h-[var(--height)] overflow-hidden'>
      <div ref={bgImgRef} 
        style={{'--bg-img-height':eventsHeaderHeight+eventImgHeight+'px'}}
        className='flex flex-col w-full h-[var(--bg-img-height)] py-24 bg-[url(/public/images/bg_7.jpg)] bg-cover bg-center'>
        <div style={{'--padding-left':space+'px'}} 
            className='flex flex-col space-y-10 w-auto h-auto pl-[var(--padding-left)]'>
          <p className='text-[30px] text-[rgb(59,59,59)] font-leBeauneNew'>upcoming events</p>
          <button className='flex flex-row items-center justify-center w-fit h-6 text-[rgb(59,59,59)] text-xs tracking-wider font-jostBold uppercase border-b border-[rgb(210,210,210)]'>
            view all events
            <MdArrowRightAlt size={22} className='ml-2'/>
          </button>
        </div>
      </div>
      <div ref={eventsSliderRef} 
          style={{top:eventsHeaderHeight+'px','--slider-height':eventImgHeight+eventContentHeight+slideShadowAllow+'px'}} 
          className='absolute flex flex-row w-full h-[var(--slider-height)] overflow-hidden'
      >
        {events && (events.map((event,i) => 
          <div key={i} 
            style={{'--event-slide-width':slideWidth+'px',transition:'left 1s ease-in-out',boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
            className='event-slide absolute flex flex-col top-8 w-[var(--event-slide-width)] h-auto bg-white'>
            <img 
              src={event.image}
              style={{'--event-img-height':eventImgHeight+'px'}} 
              className='w-full h-[var(--event-img-height)] object-cover'
            />
            <div style={{'--event-content-height':eventContentHeight+'px'}} 
              className='flex flex-col w-full h-[var(--event-content-height)] p-8'
            >
              <p className='text-xs text-[rgb(180,180,180)] font-jostSemi tracking-wide'>{event.date}</p>
              <p style={{display:'-webkit-box', WebkitBoxOrient:'vertical',WebkitLineClamp:'2'}} 
                className='flex w-full h-auto pt-4 lg:text-lg text-[rgb(59,59,59)] font-semibold font-leBeauneNew overflow-hidden overflow-ellipsis'>
                  {event.title}
              </p>
              <div className='absolute flex flex-col space-y-2 bottom-8 w-auto h-auto'>
                <div className='flex flex-row space-x-2 items-center'>
                  <LiaClockSolid size={16} className='text-[rgb(0,175,240)]'/>
                  <p className='text-xs text-[rgb(0,175,240)] font-jostSemi'>{event.start+' - '+event.end}</p>
                </div>
                <div className='flex flex-row space-x-2 items-center'>
                  <SlLocationPin size={16} className='text-[rgb(0,175,240)]'/>
                  <p className='text-xs text-[rgb(0,175,240)] font-jostSemi'>{event.venue}</p>
                </div>
              </div>
            </div>
          </div>
          )
        )}
      </div>
      <div style={{'--bullets-height':bulletsHeight+'px'}}
          className='absolute flex flex-row space-x-2 items-center justify-center bottom-0 w-full h-[var(--bullets-height)]'>
          {events && (
            (() => {
              let bullets = [];
              for(let i = 0;i < events.length - (visibleSlides - 1);i++) {
                bullets.push(<Bullet key={i} index={i} currentIndex={index} slide={slide}/>);
              }
              return bullets;
            })()
          )}
        </div>
    </div>
  )
}

export default Home