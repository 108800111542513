import React,{useEffect,useState} from 'react'
import CPDService from '../services/UserService'
import Table from './Table'
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { GoReport } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";

const CPDs = ({id}) => {
    const [subDialog,setSubDialog] = useState(null);
    const columns = [
        {
            name:'id',
            label:'Id',
            type:'text',
            filter:''
        },
        {
            name:'shortname',
            label:'Shortname',
            type:'text',
            filter: ''
        },
        {
            name:'fullname',
            label:'Fullname',
            type:'text',
            Filter: ''
        },
        {
            name:'idNumber',
            label:'Id Number',
            type:'text',
            Filter: ''
        },
        {
            name:'startDate',
            label:'Start Date',
            type:'date',
            filter:''
        }
    ]

    const getCPDs = async () => {
        let object = {}
        await CPDService.getCPDs(false)
        .then((response) => {
            if(response.content) {
                if(response.content.cpds) {
                    for(let cpd of response.content.cpds) {
                        cpd.id = cpd.id+"";
                        cpd.startDate = new Date(cpd.startDate);
                    }
                }
                object = {status:'SUCCESSFUL',content:response.content.cpds?response.content.cpds:[]}
            } else {
                object = {status:'FAILED',content:[]};
            }
        })
        .catch((error) => {
            console.log(error.message);
            object = {status:'ERROR',content:[]};
        });
        return object;
    }

    const getButtons = (user) => {
        let buttons = [
            {
                name:'View Report',
                disabled:(selected) => {
                    return selected?false:true;
                },
                onClick:(e) => setSubDialog({
                    title:'Report',
                    Content:() => <div className='flex flex-col w-96 h-80'>Report</div>
                }),
                Icon:GoReport
            }
        ];   
        return buttons;
    }
  return (
    <div className='relative flex w-full h-full'>
        <div className='flex w-full h-full no-scrollbar overflow-y-auto'>
            <Table columns={columns} retrieveRecords={getCPDs} getButtons={getButtons}/>
        </div>
        {subDialog &&
        <div className='absolute flex w-full h-full items-center justify-center'>
            <div className='flex flex-col w-auto h-auto max-w-full max-h-full shadow-xl bg-white'>
                <div
                    className='flex flex-row w-full h-10 items-center pl-4 pr-4 shrink-0 text-[rgb(150,150,150)] text-sm font-jostBook tracking-wider bg-[rgb(247,247,247)]'>
                    <div className='flex flex-row space-x-2 items-center w-full'>
                        <p>{subDialog.title}</p>
                    </div>
                    <button onClick={(e) => setSubDialog(null)} 
                        className='flex w-6 h-6 shrink-0 hover:bg-[rgb(235,235,235)]'>
                        <IoCloseOutline size={24}/>
                    </button>
                </div>
                <subDialog.Content/>
            </div>
        </div>
        }
    </div>
  )
}

export default CPDs