import React, {useState,useEffect,useContext,useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import {CIUserSettings,CIRole,CIAccount,CITransaction,CISubscription} from "./CoazIcons";
import { AiOutlineMenu } from "react-icons/ai";
import { LiaAngleRightSolid } from "react-icons/lia";
import { IoCloseOutline } from "react-icons/io5";
import EditUser from './EditUser';
import Roles from './Roles';
import Account from './Account';
import Events from './Events';
import Transactions from './Transactions';
import CPDTable from './CPDTable';

const UserSettings = ({id,firstname,lastname,self = false}) => {
    const {setDialog,mobileMode} = useContext(GlobalContext);
    const [selectedItem,setSelectedItem] = useState(null);
    const [titleBarHeight,setTitleBarHeight] = useState(40);
    const [bodyHeight,setBodyHeight] = useState(64);
    const [menuOpened,setMenuOpened] = useState(false);
    const mainContainerRef = useRef(null);

    const calcHeights = (mainContainerHeight) => {
        setBodyHeight(mainContainerHeight - titleBarHeight);
    }
    const settingsItems = [
        {
            name:'Profile Info',
            Icon: CIUserSettings,
            Component:() => <EditUser id={id} self={self}/>
        },
        {
            name:'Roles',
            Icon: CIRole,
            Component:() => <Roles id={id} edit={!self}/>
        },
        {
            name:'Transactions',
            Icon:CITransaction,
            Component:() => <Transactions id={id}/>
        },
        {
            name:'Account',
            Icon:CIAccount,
            Component:() => <Account id={id}/>
        },
        {
            name:'CPDs',
            Icon:CISubscription,
            Component:() => <CPDTable id={id}/>
        },
        {
            name:'Events',
            Icon:CISubscription,
            Component:() => <Events id={id}/>
        }
    ]

    useEffect(() => { 
        if(!selectedItem) {
            setSelectedItem(settingsItems[0]);
        }

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let height = entry.target.getBoundingClientRect().height;
                calcHeights(height);
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    return (
        <div ref={mainContainerRef} 
            onClick={(e) => {
                e.preventDefault();
                setMenuOpened(false);
            }}
            className='relative flex flex-row w-[95vw] lg:w-[80vw] h-[85vh] overflow-hidden lg:overflow-visible'>
            <div style={{transition:'all .5s ease-in-out',boxShadow:menuOpened?'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)':''}}
                className={`absolute lg:static flex flex-col w-[80%] lg:w-1/4 h-full py-8 space-y-4 z-20 lg:z-auto ${menuOpened?'left-0':'-left-[80%]'}  bg-[rgb(238,238,238)]`}>
                {settingsItems.map((settingsItem,i) =>
                    <button key={i} 
                        onClick={(e) => {
                            e.preventDefault();
                            setMenuOpened(false);
                            setSelectedItem(settingsItem)
                        }} 
                        className={`flex flex-row w-full h-10 px-8 space-x-2 items-center ${selectedItem && selectedItem.name === settingsItem.name?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'}`}>
                        <settingsItem.Icon size={32} fill={selectedItem && selectedItem.name === settingsItem.name?'rgb(0,175,240)':'rgb(85,85,85)'}/>
                        <div className='tracking-wider font-jostBook'>{settingsItem.name}</div>
                    </button>
                )}
            </div>
            <div className='flex flex-col w-full lg:w-3/4 h-full bg-[rgb(252,252,252)]'>
                <div style={{height:titleBarHeight + 'px'}} 
                    className='flex flex-row w-full items-center pl-4 pr-4 shrink-0 text-[rgb(150,150,150)] text-sm font-jostBook tracking-wider bg-[rgb(247,247,247)]'>
                    {mobileMode?
                        <div className='flex flex-row justify-between items-center w-full'>
                            <button 
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setMenuOpened(true);
                            }}>
                                <AiOutlineMenu size={32}/>
                            </button>
                            <p className='text-[rgb(0,175,240)] capitalize'>
                                {selectedItem && selectedItem.name}
                            </p>
                            <button onClick={(e) => setDialog(null)} className='flex w-6 h-6 shrink-0 hover:bg-[rgb(235,235,235)]'>
                                <IoCloseOutline size={24}/>
                            </button>
                        </div>
                        :
                        <>
                        <div className='flex flex-row space-x-2 items-center w-full'>
                            <p className='capitalize'>
                                {firstname.toLowerCase()+' '+lastname.toLowerCase()}
                            </p>
                            <LiaAngleRightSolid size={14}/>
                            <p className='text-[rgb(0,175,240)] capitalize'>
                                {selectedItem && selectedItem.name}
                            </p>
                        </div>
                        <button onClick={(e) => setDialog(null)} className='flex w-6 h-6 shrink-0 hover:bg-[rgb(235,235,235)]'>
                            <IoCloseOutline size={24}/>
                        </button>
                        </>
                    }
                </div>
                <div style={{height:bodyHeight + 'px'}} 
                    className='flex w-full'>
                    {selectedItem && (<selectedItem.Component/>)}
                </div>
            </div>
        </div>
    )
}

export default UserSettings