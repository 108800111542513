import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
import EditUser from './EditUser';

const User = ({id}) => {
    const {setReloadUser,setDialog} = useContext(GlobalContext);
    const [user,setUser] = useState(null);

    const navigate = useNavigate();

    const loadUser = async () => {
        await UserService.getUser(id,false)
        .then((response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                setUser(response.content);
            }  else {
                setUser(null);
                console.log(response)
                navigate('/home');
                setReloadUser({reload:true});
            }
        })
        .catch((error) => {
            console.log(error.message);
            navigate('/home');
            setReloadUser({reload:true});
        })
    }

    useEffect(() => {
        loadUser();
    },[])
  return (
    <div className='flex flex-col w-full h-auto overflow-hidden '>
        {user?
            <div className='flex flex-col w-full h-auto max-h-[80vh] pt-16 items-center no-scrollbar overflow-x-hidden overflow-y-auto bg-white font-jostBook'>
                <img src={user.image?user.image:user.sex.toLowerCase() == 'male'?'/images/male.svg':'/images/female.svg'} 
                    style={{width:'288px',height:'345.6px'}}
                    className='flex object-cover shadow-xl rounded-md shrink-0'
                />
                <div className='flex flex-col items-center justify-center w-full h-auto py-8 space-y-2'>
                    <div className='flex flex-row w-auto h-auto space-x-2 text-[22px] text-[rgb(59,59,59)] font-semibold'>
                        <p className='flex w-auto h-auto'>
                            {user.firstname?user.firstname:''}
                        </p>
                        <p className='flex w-auto h-auto'>
                            {user.lastname?user.lastname:''}
                        </p>
                    </div>
                    
                    <p className='flex w-auto text-lg text-[rgb(100,100,100)] lowercase'>
                        {user.username?user.username:''}
                    </p>
                    <p className='flex w-auto text-lg text-[rgb(100,100,100)] capitalize'>
                        {user.role && user.role.displayName?user.role.displayName:''}
                    </p>
                    <p className='text-sm tracking-wider text-[rgb(100,100,100)] italic'>
                        {user.email?user.email:''}
                    </p>
                    <button 
                        onClick={(e) => {
                            e.preventDefault();
                            setDialog({
                                show:true,
                                Component:() => 
                                    <EditUser id={id} reload={loadUser} self={false}/>
                            })
                        }}
                        className='flex w-auto text-sm text-[rgb(0,175,240)] hover:underline'>
                        Edit Details
                    </button>
                </div>
                <div className='flex flex-col space-y-8 w-full h-auto px-[10%] py-16 shrink-0 bg-[rgb(243,244,245)]'>
                    <div className='flex flex-col w-full h-auto space-y-4'>
                        <p className='text-3xl text-[rgb(59,59,59)] font-semibold'>Personal Details</p>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Firstname</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.firstname}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Lastname</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.lastname}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Middlename</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.middlename}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Gendar</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.sex}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Date of birth</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.dateOfBirth instanceof Date?user.dateOfBirth.getDate()+'/'+(user.dateOfBirth.getMonth()+1)+'/'+user.dateOfBirth.getFullYear():user.dateOfBirth}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Id Type</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.idType}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Id Number</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.idNumber}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Nationality</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.nationality}</p>
                        </div>
                    </div>
                    <div className='flex flex-col w-full h-auto space-y-4'>
                        <p className='text-3xl font-semibold'>Contact Details</p>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Email</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.email}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Phone 1</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.phone1}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Phone 2</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.phone2}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Physical Address</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.physicalAddress}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Postal Address</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.postalAddress}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Province</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.province}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Destrict</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.destrict}</p>
                        </div>
                    </div>
                    <div className='flex flex-col w-full h-auto space-y-4'>
                        <p className='text-3xl font-semibold'>Education Details</p>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Institution</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.institution}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Professional Category</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.professionalCategory}</p>
                        </div>
                    </div>
                    <div className='flex flex-col w-full h-auto space-y-4'>
                        <p className='text-3xl font-semibold'>Employement Details</p>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Sector</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.sector}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Employer</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.employer}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Organisational Unit</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.organisatioalUnit}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Current Position</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.currentPosition}</p>
                        </div>
                        <div className='flex flex-row w-full h-auto'>
                            <p className='flex text-[rgb(59,59,59)] font-semibold w-1/2 h-auto'>Facility</p>
                            <p className='flex text-[rgb(100,100,100)] w-1/2 h-auto'>{user.facility}</p>
                        </div>
                    </div>
                </div>
            </div> :
            <div>
                
            </div>
        }
    </div>
  )
}

export default User