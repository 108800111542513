import React, {useState,useEffect,useContext,useRef} from 'react'
import { LiaAngleLeftSolid,LiaAngleRightSolid } from "react-icons/lia";
import { GlobalContext } from '../contexts/GlobalContext';
import {sex,provinces,nationalities,idTypes,userStatuses} from '../data';
import Checkbox from './Checkbox';
import { useNavigate } from 'react-router-dom';
import TransactionService from '../services/TransactionService';
import { TbUserPlus,TbUsersPlus,TbUserEdit,TbUserX,TbPasswordUser,TbUserCheck } from "react-icons/tb";
import { IoCloseOutline } from "react-icons/io5";
import AddUser from './AddUser';
import AddUsers from './AddUsers';
import UserSettings from './UserSettings';

  const Transactions = ({id}) => {
    const {setDialog,setReloadUser,mobileMode} = useContext(GlobalContext);
    const [transactions,setTransactions] = useState([]);
    const [selected,setSelected] = useState(null);
    const [pageNo,setPageNo] = useState(0);
    const [pageSize,setPageSize] = useState(0);
    const [totalElements,setTotalElements] = useState(0);
    const [totalPages,setTotalPages] = useState(0);
    const [last,setLast] = useState(true);
    const [booleanOptions,setBooleanOptions] = useState(['Yes','No']);
    const [message,setMessage] = useState({content:'',success:false});
    const [filter,setFilter] = useState({
        paymentType:'',
        currency:'',
        amount:0.0,
        accountNumber:'',
        ipAddress:'',
        message:'',
        transactionId:'',
        externalId:id?id+'':'',
        createdOnDay:'',
        createdOnMonth:'',
        createdOnYear:'',
        status:''
    });
    const [page,setPage] = useState({
        pageNo:0,
        pageSize:10,
        sortBy:'id',
        sortDir:'asc'
    })
    const mainContainerRef = useRef(null);
    const tableRef = useRef(null);
    const headerRef = useRef(null);
    const [tableContainerHeight,setTableContainerHeight] = useState(0);
    const [tableHeight,setTableHeight] = useState(0);
    const [tHeaderHeight,setTHeaderHeight] = useState(32);
    const [tBodyHeight,setTBodyHeight] = useState(0);
    const [pagenationInfoHeight,setPagenationInfoHeight] = useState(64);
    const calcHeights = (mainContainerHeight) => {
        let height = mainContainerHeight;
        setTableContainerHeight(height);
        height -= pagenationInfoHeight;
        setTableHeight(height);
        setTBodyHeight(height - tHeaderHeight);
    }
    
    let timerId;

    const onDay = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        if(value > 31) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onMonth = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        if(value > 12) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onNumber = (e) => {
        const value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    }
    const onBoolean = (e) => {
        const value = e.target.value === 'Yes'?true:e.target.value === 'No'?false:null;
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    };
    const onChange = (e) => {
        const value = e.target.value;
        setFilter({...filter, [e.target.name]: value});
        setFocused(e.target.name);
    };
    
    const [focused,setFocused] = useState('');
    const columns = [
        {
            name:'paymentType',
            label:'Payment Type',
            Filter: () => 
                <input 
                    type='text' 
                    name='paymentType'
                    value={filter.paymentType}
                    autoFocus={focused === 'paymentType'}
                    placeholder='Filter...'
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'currency',
            label:'Currency',
            Filter: () => 
                <select
                    type="text" 
                    name="currency"
                    value={filter.currency?filter.currency:''}
                    autoFocus={focused === 'currency'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex h-8 shrink-0 px-1 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                > 
                    <option value=''>All</option>
                    <option value='ZMW'>ZMW</option>
                    <option value='USD'>USD</option>
                    <option value='ZAR'>ZAR</option>
                </select>
        },
        {
            name:'amount',
            label:'Amount',
            Filter: () => 
                <input 
                    type="text" 
                    name="amount"
                    value={filter.amount}
                    autoFocus={focused === 'amount'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'accountNumber',
            label:'Account Number',
            Filter: () => 
                <input 
                    type="text" 
                    name="accountNumber"
                    value={filter.accountNumber}
                    autoFocus={focused === 'accountNumber'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'ipAddress',
            label:'IP Address',
            Filter: () => 
              <input 
                type="text" 
                name="ipAddress"
                value={filter.ipAddress}
                autoFocus={focused === 'ipAddress'}
                placeholder="Filter..."
                onChange={onChange}
                onClick={e => e.stopPropagation()}
                className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
            />
        },
        {
            name:'message',
            label:'Message',
            Filter: () => 
                <input 
                    type="text" 
                    name="message"
                    value={filter.message}
                    autoFocus={focused === 'message'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'transactionId',
            label:'Transaction Id',
            Filter: () => 
                <input 
                    type="text" 
                    name="transactionId"
                    value={filter.transactionId}
                    autoFocus={focused === 'transactionId'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'externalId',
            label:'External Id',
            Filter: () => 
                <input 
                    type="text" 
                    name="externalId"
                    value={filter.externalId}
                    autoFocus={focused === 'externalId'}
                    disabled={id}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex w-full h-8 shrink-0 p-2 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                />
        },
        {
            name:'createdOn',
            label:'Created On',
            Filter: () => 
                <div className='flex flex-row items-center justify-center w-auto h-8 px-2 shrink-0 text-[rgb(93,93,93)] font-jostBook text-sm '>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={2}
                        name="createdOnDay"
                        value={filter.createdOnDay > 0?filter.createdOnDay:''}
                        autoFocus={focused === 'createdOnDay'}
                        placeholder="DD"
                        onChange={onDay}
                        onClick={e => e.stopPropagation()}
                        className='flex w-6 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    />
                    <p className=''>/</p>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={2}
                        name="createdOnMonth"
                        value={filter.createdOnMonth > 0?filter.createdOnMonth:''}
                        autoFocus={focused === 'createdOnMonth'}
                        placeholder="MM"
                        onChange={onMonth}
                        onClick={e => e.stopPropagation()}
                        className='flex w-6 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    />
                    <p className=''>/</p>
                    <input 
                        type='text'
                        inputMode='numeric'
                        maxLength={4}
                        name='createdOnYear'
                        value={filter.createdOnYear > 0?filter.createdOnYear:''}
                        autoFocus={focused === 'createdOnYear'}
                        placeholder='YYYY'
                        onChange={onNumber}
                        onClick={e => e.stopPropagation()}
                        className='flex w-9 h-full text-right focus:outline-none tracking-wider whitespace-nowrap bg-transparent'
                    /> 
                </div>
        },
        {
            name:'status',
            label:'Status',
            Filter: () => 
                <select
                    type="text" 
                    name="status"
                    value={filter.status}
                    autoFocus={focused === 'status'}
                    placeholder="Filter..."
                    onChange={onChange}
                    onClick={e => e.stopPropagation()}
                    className='flex h-8 shrink-0 px-1 focus:outline-none text-[rgb(93,93,93)] font-jostBook text-sm tracking-wider whitespace-nowrap bg-transparent'
                > 
                    <option value=''>All</option>
                    {userStatuses.map((option,i) => <option key={i} value={option}>{option}</option>)}
                </select>
        }
    ]

    const handleNext = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo < totalPages - 1) {
            setPage({...page, pageNo: page.pageNo + 1});
        }
    }

    const handlePrevious = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo > 0) {
            setPage({...page, pageNo: page.pageNo - 1});
        }
    }

    const navigate = useNavigate();

    const getTransactions = async  () => {
        if(id) {
            await TransactionService.getMyTransactions(id,filter,page,false)
            .then((response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                    setTransactions(response.content);
                    setPageNo(response.pageNo);
                    setPageSize(response.pageSize);
                    setTotalElements(response.totalElements);
                    setTotalPages(response.totalPages);
                    setLast(response.last);
                } else {
                    console.log(response);
                    navigate('/home');
                }
            })
            .catch((error) => {
                console.log(error.message);
                navigate('/home');
            })
        } else {
            await TransactionService.getTransactions(filter,page,false)
            .then((response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                    setTransactions(response.content);
                    setPageNo(response.pageNo);
                    setPageSize(response.pageSize);
                    setTotalElements(response.totalElements);
                    setTotalPages(response.totalPages);
                    setLast(response.last);
                } else {
                    console.log(response);
                    navigate('/home');
                }
            })
            .catch((error) => {
                console.log(error.response);
                navigate('/home');
            })
        }
        
        setReloadUser({reload:true});
    }

    useEffect(() => {
        getTransactions();

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let height = entry.target.getBoundingClientRect().height;
                calcHeights(height);
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[page]);

    return (
        <div ref={mainContainerRef} className='flex flex-col w-full h-full'>
            <div style={{height:tableContainerHeight + 'px'}}  className='relative flex flex-row w-full'>
                {!mobileMode &&
                    <div style={{top:tHeaderHeight + 'px',bottom:tHeaderHeight + 'px'}} 
                        className='absolute flex -left-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: -32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.3s linear'
                                }}
                            className={`${true?'visible opacity-100':'invisible opacity-0'} flex w-10 h-10 m-auto bg-[rgb(255,255,255)] rounded-full`}
                        >
                            <LiaAngleLeftSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
                {/* <button 
                    onMouseDown={(e) => {
                        if(tableRef.current) {
                            timerId = setInterval(() => {
                                tableRef.current.scrollBy({
                                    top: 0,
                                    left: -32,
                                    behavior: "smooth",
                                });
                            },100);
                        }    
                    }} 
                    onMouseUp={(e) => {
                        timerId && clearInterval(timerId);
                    }}
                    onMouseOut={(e) => {
                        timerId && clearInterval(timerId);
                    }}
                    style={{height: tHeaderHeight + 'px',transition:'all .5s ease-in-out'}}
                    className='flex items-center justify-center w-8 shrink-0 text-[rgb(93,93,93)] hover:text-[rgb(0,175,240)]'><LiaAngleLeftSolid size={14}/>
                </button> */}
                <div className='flex flex-col w-full h-full px-5 overflow-hidden'>
                    <div ref={tableRef}
                        style={{height: tableHeight + 'px'}} 
                        className='flex flex-col w-full text-sm  no-scrollbar overflow-x-auto overflow-y-hidden'>
                        <div ref={headerRef}
                            style={{height: tHeaderHeight + 'px',transition:'all .5s ease-in-out'}}
                            className='flex flex-row w-fit shrink-0 font-jostBold text-black border-b cursor-pointer'
                            onClick={(e) => {
                                let height = tHeaderHeight === 32?68:32;
                                setTHeaderHeight(height);
                                setTBodyHeight(tableContainerHeight - height);
                                setFocused('');
                            }}
                            onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        getTransactions();
                                    }
                                }
                            }>
                            {columns.map((column,i) => 
                                <div key={i} className={column.name+' flex flex-col h-full overflow-hidden'}>
                                    <p className='flex h-8 shrink-0 items-center px-2 text-left whitespace-nowrap'>{column.label}</p>
                                    <div onClick={(e) => e.stopPropagation()} 
                                        className='flex w-fit h-8 shrink-0'>
                                        <column.Filter/>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{height: tBodyHeight + 'px'}}  
                            className='flex w-fit overflow-hidden'>
                            {transactions && <Page page={transactions} columns={columns} headerRef={headerRef} selected={selected} setSelected={setSelected}/>}
                        </div>
                    </div>
                    <div style={{height: pagenationInfoHeight + 'px'}}
                        className='flex flex-row-reverse w-full shrink-0 items-end text-[rgb(100,100,100)]'>
                            {page && 
                            <div className='flex flex-row space-x-1 w-fit h-full items-center'>
                                <p className='font-jostBook text-xs'>
                                    {(pageNo * pageSize)+1+' -- '+((pageNo * pageSize)+transactions.length)+' of '+totalElements}
                                </p>
                                <button 
                                    disabled={!pageNo > 0}
                                    onClick={(e) => handlePrevious(e)}
                                    className={`flex w-10 h-10 m-auto ${pageNo > 0?'hover:bg-[rgb(235,235,235)]':'text-[rgb(170,170,160)]'} rounded-full`}
                                >
                                    <LiaAngleLeftSolid size={16} className='flex m-auto'/>
                                </button>
                                <button 
                                    disabled={last}
                                    onClick={(e) => handleNext(e)}
                                    className={`flex w-10 h-10 m-auto ${last?'text-[rgb(170,170,170)]':'hover:bg-[rgb(235,235,235)]'} rounded-full`}
                                >
                                    <LiaAngleRightSolid size={16} className='flex m-auto'/>
                                </button>
                            </div>
                            }
                    </div>
                </div>
                {/* <button 
                    onMouseDown={(e) => {
                        if(tableRef.current) {
                            //setDisableLScrollBtn(false);
                            timerId = setInterval(() => {
                                tableRef.current.scrollBy({
                                    top: 0,
                                    left: 32,
                                    behavior: "smooth",
                                });
                            },100);
                        }    
                    }} 
                    onMouseUp={(e) => {
                        timerId && clearInterval(timerId);
                    }}
                    onMouseOut={(e) => {
                        timerId && clearInterval(timerId);
                    }}
                    style={{height: tHeaderHeight + 'px',transition:'all .5s ease-in-out'}}
                    className='flex items-center justify-center w-8 text-[rgb(93,93,93)] hover:text-[rgb(0,175,240)]'><LiaAngleRightSolid size={14}/>
                </button> */}
                {!mobileMode &&
                    <div style={{top:tHeaderHeight + 'px', bottom:tHeaderHeight + 'px'}} 
                        className='absolute flex -right-5 w-10'>
                        <button 
                            onMouseDown={(e) => {
                                if(tableRef.current) {
                                    //setDisableLScrollBtn(false);
                                    timerId = setInterval(() => {
                                        tableRef.current.scrollBy({
                                            top: 0,
                                            left: 32,
                                            behavior: "smooth",
                                        });
                                    },100);
                                }    
                            }} 
                            onMouseUp={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            onMouseOut={(e) => {
                                timerId && clearInterval(timerId);
                            }}
                            style={{backdropFilter:'blur(10px)',
                                    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                    transition: 'all 0.5s linear'
                                }}
                            className={`${false?'invisible opacity-0':'visible opacity-100'} flex w-10 h-10 m-auto bg-white rounded-full`}
                        >
                            <LiaAngleRightSolid size={16} className='flex m-auto text-gray-600'/>
                        </button>
                    </div>
                }
                
            </div>
        </div>
    )
}

const Page = ({page,columns,headerRef,selected,setSelected}) => {
    const pagesRef = useRef(null);
    function getTextWidth(text,font,fontSize) { 
        let span = document.createElement("span"); 
        document.body.appendChild(span); 
        span.style.fontFamily = font; 
        span.style.fontSize = fontSize + "px"; 
        span.style.height = 'auto'; 
        span.style.width = 'auto'; 
        span.style.position = 'absolute'; 
        span.style.whiteSpace = 'no-wrap'; 
        span.innerHTML = text; 
     
        let width = Math.ceil(span.clientWidth);   
        document.body.removeChild(span); 
        return width;
    } 

    useEffect(() => {
        if(!pagesRef.current) {
            return;
        }
        if(!headerRef.current) {
            return;
        }
        let totalWidth = 0;
        for(let column of columns) {
            const columnHeader = headerRef.current.getElementsByClassName(column.name)[0];
            if(!columnHeader) {
                continue;
            }
            const columnLabel = columnHeader.getElementsByTagName('p')[0];
            const columnFilter = columnHeader.getElementsByTagName('div')[0];
            let width = 0;
            if(columnLabel){
                width = getTextWidth(columnLabel.textContent,'Jost-700-Bold',14)+16;
            }
            if(columnFilter){
                const filterWidth = columnFilter.getBoundingClientRect().width;
                width = filterWidth > width?filterWidth:width;  
            }
            const elements = pagesRef.current.getElementsByClassName(column.name);
            for(let element of elements){
                let w = getTextWidth(element.textContent,'Jost-400-Book',14)+16;
                if(w > width){
                    width = w;
                }
            }
            columnHeader.style.width = width+'px';
            for(let element of elements){
                element.style.width = width+'px';
            }
            totalWidth += width;
        }
    },[page]);

    return (
        <div ref={pagesRef} className='flex flex-col w-fit h-full shrink-0 no-scrollbar overflow-y-auto'>
            {page.map((transaction,i) =>
                <div key={i} 
                    onClick={(e) => setSelected(transaction)}
                    className={`flex flex-row w-fit h-10 shrink-0 items-center font-jostBook ${selected && selected.id === transaction.id?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'} cursor-pointer`}>
                    {columns.map((column,i) => 
                        <div key={i} className={column.name+' flex w-fit start-0 px-2 text-left whitespace-nowrap'}>
                            {transaction[column.name] instanceof Date?transaction[column.name]:
                            typeof transaction[column.name] === 'boolean'?transaction[column.name]?'Yes':'No':
                            transaction[column.name]}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Transactions

