import React, {useState, useEffect, useContext, useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useLocation,useParams,Outlet,NavLink } from 'react-router-dom';
import {Logo} from './CoazIcons';
import ReceivableService from '../services/ReceivableService';
import UserService from '../services/UserService';

const Access = () => {
    const navigate = useNavigate();
    const path = useLocation().pathname;
    const {firstname,lastname,receivableid,userid} = useParams();
    const [eventName,setEventName] = useState('');
    const [isVertAligned,setVertAligned] = useState(false);

    useEffect(() => { 
        if(!path.includes('login') && !path.includes('register') && !path.includes('changepassword') && 
            !path.includes('confirm') && !path.includes('payment') && !path.includes('finishregistration') &&
            !path.includes('eventregistration')) {
            navigate('/access/login');
        }
        if((path.includes('eventregistration') || path.includes('payment')) && receivableid) {
            ( async () => {
                await ReceivableService.getReceivable(receivableid)
                .then((response) => {
                    if(response.content) {
                        setEventName(response.content.name);
                    }  else {
                        console.log(response);
                        UserService.logout();
                        navigate('/home');
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    UserService.logout();
                    navigate('/home');
                })
            }
            )();
        }
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let rect = entry.target.getBoundingClientRect();
                if(rect.width < rect.height) {
                    setVertAligned(true);
                } else {
                    setVertAligned(false);
                }
            }
          });
          observer.observe(document.documentElement)
          return () => {
            observer.disconnect();
          };

    },[path]);

    return (
        <div className='flex items-center justify-center w-screen h-screen bg-[url(/public/images/img_6.jpg)] bg-cover bg-center overflow-hidden'>
            <div className='absolute flex  left-0 top-0 w-full h-full shrink-0 bg-gradient-to-b from-[rgba(0,0,0,.3)] via-transparent to-[rgba(0,0,0,.5)]'/>
            <div className={`flex ${isVertAligned?'flex-col w-[512px] h-[1024px] space-y-2':'flex-row w-[1024px] h-[512px] space-x-2'} max-w-[90%] max-h-[90%] items-center justify-between overflow-hidden rounded-xl`}>
                <div style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.1), 0 10px 20px 20px rgba(0, 0, 0, 0.07)'}}
                    className={`relative flex ${isVertAligned?'w-full h-auto rounded-tl-xl rounded-tr-xl':'w-80 h-full rounded-tl-xl rounded-bl-xl'} shrink-0 p-4 bg-[rgba(0,0,0,.5)] overflow-hidden`}>
                    <div className='relative flex flex-col w-full h-full space-y-4 items-center justify-center text-white '>
                        <div onClick={(e)=> UserService.logout()}>
                            <NavLink to={'/home'}>
                                <Logo size={96} fill='rgb(0,175,240)'/>
                            </NavLink>
                        </div>
                        <div className='relative w-full h-24'>
                            {path.includes('login') || path.includes('register')?
                            <><div className={`absolute flex flex-row w-full justify-center ${path.includes('login')?'top-0':'bottom-0'}`}>
                                <p className={`text-center ${path.includes('login')?'font-leBeauneNew font-semibold text-5xl':'text-[rgb(220,220,220)] font-jostLight'}`}>{path.includes('login')?"Login":"If you're already registered,"}</p>
                                {!path.includes('login') && (<NavLink to={'/access/login'}><button className='text-[rgb(220,220,220)] font-jostBook'>login</button></NavLink>)}
                            </div>
                            <div className={`absolute flex flex-row w-full justify-center ${path.includes('register')?'top-0':'bottom-0'}`}>
                                <p className={`text-center ${path.includes('register')?'font-leBeauneNew font-semibold text-5xl':'text-[rgb(220,220,220)] font-jostLight'}`}>{path.includes('register')?"Register":"If you haven't registered, "}</p>
                                {!path.includes('register') && (<NavLink to={'/access/register'}><button className='text-[rgb(220,220,220)] font-jostBook'>register</button></NavLink>)}
                            </div></>:
                            path.includes('changepassword')? 
                            <div className='absolute flex flex-row w-full justify-center top-0'>
                                <p className='text-center font-leBeauneNew font-semibold tracking-widest text-2xl'>Change Password</p>
                            </div>:
                            path.includes('confirm')? 
                            <div className='absolute flex flex-col space-y-2 w-full justify-center top-0'>
                                <p className='text-center font-leBeauneNew font-semibold tracking-widest text-2xl'>Confirm User</p>
                                <p className='px-8 text-center text-[rgb(220,220,220)] font-jostLight'>
                                    A confirmation code was sent to your email
                                </p>
                            </div>:
                            path.includes('payment')? 
                            <div className='absolute flex flex-col space-y-2 w-full justify-center top-0'>
                                <p className='text-center font-leBeauneNew font-semibold tracking-widest text-2xl'>{`${eventName} Payment`}</p>
                                <p className='px-8 text-center text-[rgb(220,220,220)] font-jostLight'>
                                    You can transact with Airtel Money, Mtn MoMo, Zamkwacha and Visa Card
                                </p>
                            </div>:
                            path.includes('finishregistration')? 
                            <div className='absolute flex flex-col space-y-2 w-full justify-center top-0'>
                                <p className='text-center font-leBeauneNew font-semibold tracking-wider text-2xl'>{firstname && lastname?'Hi '+firstname+' '+lastname+'!':'Registration'}</p>
                                <p className='px-8 text-center text-[rgb(220,220,220)] font-jostLight'>
                                    Fill the required fields to complete the registration process
                                </p>
                            </div>:
                            path.includes('eventregistration')? 
                            <div className='absolute flex flex-col space-y-2 w-full justify-center top-0'>
                                <p className='text-center font-leBeauneNew font-semibold tracking-wider text-2xl'>{eventName+' registration'}</p>
                                <p className='px-8 text-center text-[rgb(220,220,220)] font-jostLight'>
                                    Fill the required fields to register
                                </p>
                            </div>:
                            <></>
                            }
                        </div>
                    </div>
                </div>
                <div style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.1), 0 10px 20px 20px rgba(0, 0, 0, 0.07)'}}
                    className={`relative flex ${isVertAligned?'w-full h-full rounded-br-xl rounded-bl-xl':'w-full h-full rounded-tr-xl rounded-br-xl'} overflow-hidden py-8 bg-white`}>
                    <Outlet context={[receivableid,userid]}/>
                </div>
            </div>
            
        </div>
    )
}

export default Access